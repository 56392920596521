<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div class="content" *ngIf="!isLoading">
 

  <table mat-table [dataSource]="dataSource" multiTemplateDataRows width="100%">
    <!-- ['api_category_name', 'display_name', 'flag', 'priority', 'star'] -->
    <ng-container matColumnDef="api_category_name">
      <th mat-header-cell *matHeaderCellDef> sssssssssAPI Category Name </th>
      <td mat-cell *matCellDef="let element">{{element.Category}}</td>
    </ng-container>

    <ng-container matColumnDef="display_name">
      <th mat-header-cell *matHeaderCellDef fxHide.lt-sm>Display Name</th>
      <td mat-cell *matCellDef="let element" fxHide.lt-sm>{{element.display_name}}</td>
    </ng-container>

    <ng-container matColumnDef="flag">
      <th mat-header-cell *matHeaderCellDef>Flag</th>
      <td mat-cell *matCellDef="let element">{{element.Flag}}</td>
    </ng-container>

    <ng-container matColumnDef="priority">
      <th mat-header-cell *matHeaderCellDef>Priority</th>
      <td mat-cell *matCellDef="let element">{{element.Priority_value}}</td>
    </ng-container>

    <ng-container matColumnDef="star">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="clean-icon">
        <mat-menu #operations="matMenu">
          <!-- <button [disabled]="element.user_profile.role.role_name === 'MASTER_ADMIN'" mat-menu-item
            (click)="deleteUser(element)">Delete</button> -->
        </mat-menu>

        <button *ngIf="element.type != 'add'" mat-icon-button [matMenuTriggerFor]="operations">
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
        <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <form class="form" [formGroup]="element.form" (ngSubmit)="updateCategory(element.form.value)">
            <input type="hidden" formControlName="id">
            <div class="form-row">
              <mat-form-field class="width-20">
                <input matInput placeholder="Display Name" formControlName="display_name" autofocus required>
              </mat-form-field>
              <mat-form-field class="width-20">
                <input matInput placeholder="Flag" formControlName="Flag" required>
              </mat-form-field>
              <mat-form-field class="width-20">
                <input matInput placeholder="Priority" formControlName="Priority_value" required>
              </mat-form-field>
            </div>

            <div class="action">

              <button mat-stroked-button color="primary" (click)="expandedElement = null; false">Cancel</button>
              <button mat-flat-button color="primary" type="submit" *ngIf="element.type == 'update'"
                [disabled]="!element.form.valid">Save</button>
              <button mat-flat-button color="primary" type="submit" *ngIf="element.type == 'add'"
                [disabled]="!element.form.valid">Add</button>
            </div>
          </form>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
</div>

