import {environment} from 'src/environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class MenuServiceV2 {
  endpoint = environment.apiUrl;
  menuEndpoint = `${this.endpoint}/backend/business/store/`;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
    })
  };

  constructor(private http: HttpClient){}

  updateMenuGroup(storeId:string, payload: string){
    return this.http.post(this.menuEndpoint + `${storeId}/menu_management_v2/menu_group/update/`, payload, this.httpOptions);
  }

  deleteMenuGroup(storeId:string, payload: string){
    return this.http.post(this.menuEndpoint + `${storeId}/menu_management_v2/menu_group/delete/`, payload, this.httpOptions);
  }

  getStoreMenuGroups(storeId: string) {
    return this.http.get<Object>(this.menuEndpoint + `${storeId}/menu_management_v2/menu_group/`, this.httpOptions);
  }

  getStoreMenuGroup(storeId: string, menuGroupId:string, menuAscDescOrder:string) {
    return this.http.get<any>(this.menuEndpoint + `${storeId}/menu_management_v2/menu_group/${menuGroupId}/`, {params: {dir: menuAscDescOrder}});
  }

  getStoreMenuGroupMenuItemGroup(storeId: string, menuGroupId:string, menuAscDescOrder:string) {
    return this.http.get<any>(this.menuEndpoint + `${storeId}/menu_management_v2/menu_group_menu_item_group/${menuGroupId}/`, {params: {dir: menuAscDescOrder}});
  }

  getStoreMenuItemGroup(storeId: string, menuItemGroupId) {
    return this.http.get<any>(this.menuEndpoint + `${storeId}/menu_management_v2/menu_item_group/${menuItemGroupId}/`, this.httpOptions);
  }

  updateStoreMenuItemGroup(storeId: string, menuItemGroupId:string, payload:string) {
    return this.http.post<any>(this.menuEndpoint + `${storeId}/menu_management_v2/menu_item_group/${menuItemGroupId}/update/`, payload, this.httpOptions);
  }

  addMenuItemGroup(storeId:string, payload:string){
    return this.http.post(this.menuEndpoint + `${storeId}/menu_management_v2/menu_item_group/add/`, payload, this.httpOptions);
  }

  deleteStoreMenuItemGroup(storeId: string, menuItemGroupId:string) {
    return this.http.get<any>(this.menuEndpoint + `${storeId}/menu_management_v2/menu_item_group/${menuItemGroupId}/delete/`, this.httpOptions);
  }

  getStoreMenuItemsV2(storeId: string) {
    return this.http.get(this.menuEndpoint + `${storeId}/menu_management_v2/menu_item/`, this.httpOptions);
  }

  addMenuItem(storeId:string, payload:string){
    return this.http.post(this.menuEndpoint + `${storeId}/menu_management_v2/menu_item/add/`, payload, this.httpOptions);
  }

  updateMenuItem(storeId:string, payload:string){
    return this.http.post(this.menuEndpoint + `${storeId}/menu_management_v2/menu_item/update/`, payload, this.httpOptions);
  }

  deleteMenuItem(storeId:string, payload:string){
    return this.http.post(this.menuEndpoint + `${storeId}/menu_management_v2/menu_item/delete/`, payload, this.httpOptions);
  }

  getModGroup(storeId:string){
    return this.http.get(this.menuEndpoint + `${storeId}/menu_management_v2/mod_group/`, this.httpOptions);
  }

  getModifierOptions(storeId:string, modGroupId:string, availability:string, searchTerm:string, modAscDescOrder:string){
    return this.http.get<any>(this.menuEndpoint + `${storeId}/menu_management_v2/mod_group/${modGroupId}/`,
    {params: {availability, search_term: searchTerm, dir: modAscDescOrder}});
  }

  getModifierOption(storeId:string, modId:string){
    return this.http.get<any>(this.menuEndpoint + `${storeId}/menu_management_v2/mod_option/${modId}/`);
  }


  addModGroup(storeId:string, payload:string){
    return this.http.post(this.menuEndpoint + `${storeId}/menu_management_v2/mod_group/add/`, payload, this.httpOptions);
  }

  updateModGroup(storeId:string, payload:string){
    return this.http.post(this.menuEndpoint + `${storeId}/menu_management_v2/mod_group/update/`, payload, this.httpOptions);
  }

  addModifier(storeId:string, payload:string){
    return this.http.post(this.menuEndpoint + `${storeId}/menu_management_v2/modifier/add/`, payload, this.httpOptions);
  }

  updateModifier(storeId:string, payload:string){
    return this.http.post(this.menuEndpoint + `${storeId}/menu_management_v2/modifier/update/`, payload, this.httpOptions);
  }

  deleteModGroup(storeId:string, payload:string){
    return this.http.post(this.menuEndpoint + `${storeId}/menu_management_v2/mod_group/delete/`, payload, this.httpOptions);
  }

  deleteModifier(storeId:string, payload:string) {
    return this.http.post(this.menuEndpoint + `${storeId}/menu_management_v2/modifier/delete/`, payload, this.httpOptions);
  }

  getMenuItemModifiers(storeId: string, payload: string) {
    return this.http.get<any>(this.menuEndpoint.replace('/business', '') + `${storeId}/menu_item/${payload}/modifiers/`);
  }

  getModGroupForMenuItem(storeId:string, payload:string){
    return this.http.post<any[]>(this.menuEndpoint + `${storeId}/menu_management_v2/menu_item/mod_group/`, payload, this.httpOptions);
  }

  updateModGroupForMenuItem(storeId:string, payload:string){
    return this.http.post(this.menuEndpoint + `${storeId}/menu_management_v2/menu_item/mod_group/update/`, payload, this.httpOptions);
  }

  updateIndexModGroupForMenuItem(storeId:string, payload:any){
    return this.http.put(this.menuEndpoint + `${storeId}/menu_management_v2/menu_item/mod_group_update_index/`, payload, this.httpOptions);
  }
  
  deleteModGroupForMenuItem(storeId:string, payload:string){
    return this.http.post(this.menuEndpoint + `${storeId}/menu_management_v2/menu_item/mod_group/delete/`, payload, this.httpOptions);
  }

  updateOrder(storeId:string, payload:string){
    return this.http.post(this.menuEndpoint + `${storeId}/menu_management_v2/order/`, payload, this.httpOptions);
  }

  deleteImage(storeId, menuGroupId){
    return this.http.get(`${environment.apiUrl}/backend/business/store/${storeId}/menu_management_v2/menu_item_group/${menuGroupId}/image/delete/`)
  }

  deleteSingleImage(storeId, menuGroupId, imageID){
    return this.http.get(`${environment.apiUrl}/backend/business/store/${storeId}/menu_management_v2/menu_item_group/${menuGroupId}/${imageID}/image/delete/`)
  }

  getStoreMenuLazy(storeId, filter) {
    return this.http.get(`${environment.apiUrl}/backend/store/${storeId}/lazy_menu_item_groups/`,
    {params: {filter, is_pos: 'true'}});
  }

  getMenuItemsForMenuGroup(storeId, menuGroupId){
    return this.http.get<any[]>(`${environment.apiUrl}/backend/store/${storeId}/menu_item_groups/${menuGroupId}/menu_items/`,
    {params: {is_pos:'true'}});
  }

  getModifiersForMenuItem(storeId, menuItemId){
    return this.http.get(`${environment.apiUrl}/backend/store/${storeId}/menu_item/${menuItemId}/modifiers/`);
  }

  getMenuCategoryAvailability(storeId, menuGroupName){
    return this.http.get(`${environment.apiUrl}/backend/v2/menu/availability/${storeId}/`, {params: {menu_category_name: menuGroupName} });
  }

  updateMenuCategoryAvailability(payload){
    return this.http.post(`${environment.apiUrl}/backend/v2/menu/availability/`, payload, this.httpOptions);
  }

  searchMenuItem(storeId, searchTerm, isAvailable){
    return this.http.get(`${environment.apiUrl}/backend/v2/menu/search/`, {params: {store_id: storeId,
      search_term:searchTerm, availability: isAvailable} });
  }

  searchModifiers(storeId, searchTerm, isAvailable){
    return this.http.get(`${environment.apiUrl}/backend/v2/menu/mod/search/`, {params: {store_id: storeId,
      search_term:searchTerm, availability: isAvailable} });
  }

  createMenuGroupCopy(storeId:any, id:any) {
    return this.http.post(this.menuEndpoint + `${storeId}/menu_management_v2/menu_group_copy/${id}/`, this.httpOptions);
  }

  createModifierCopy(storeId:any, mod_id:any) {
    return this.http.post(this.menuEndpoint + `${storeId}/menu_management_v2/mod_group_copy/${mod_id}/`, this.httpOptions);
  }

  menuImageUpload(storeId:any, menuGroupId:any, payload:any) {
    return this.http.post(`${environment.apiUrl}/backend/business/store/${storeId}/menu_management_v2/menu_group/${menuGroupId}/image_resized/`, payload);
  }
  menuImageDelete(storeId:any, menuGroupId:any, type:any) {
    return this.http.get(`${environment.apiUrl}/backend/business/store/${storeId}/menu_management_v2/menu_group/${menuGroupId}/${type}/image/delete/`);
  }

  getGenerateBarcode(storeId, menuId){
    return this.http.get(`${environment.apiUrl}/backend/business/store/${storeId}/menu_management_v2/generate_barcode/${menuId}/`, { responseType: 'blob' });
  }
  
  createMenuCatCopy(storeId:any, id:any) {
    return this.http.post(this.menuEndpoint + `${storeId}/menu_management_v2/menu_item_group_copy/${id}/`, this.httpOptions);
  }

  createModCopy(storeId:any, mod_id:any) {
    return this.http.post(this.menuEndpoint + `${storeId}/menu_management_v2/mod_copy/${mod_id}/`, this.httpOptions);
  }
  getMenuIconList() {
    return this.http.get<Object>(`${this.endpoint}/backend/` + `store/get_menu_icon_list/`, this.httpOptions);
  }
  getModOptionIds(storeId: string) {
    return this.http.get<Object>(this.menuEndpoint + `${storeId}/menu_management_v2/mod_option_id_list/`, this.httpOptions);
  }
  getMenuItemIds(storeId: string) {
    return this.http.get<Object>(this.menuEndpoint + `${storeId}/menu_management_v2/menu_item_id_list/`, this.httpOptions);
  }
}
