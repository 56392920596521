import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TransactionHistoryDataSource} from 'src/app/_datasources/history.datasource';
import {OrdersWithPayment} from 'src/app/_models/order';
import {TransationHistory} from 'src/app/_models/order-transaction-history';
import {OrderService} from 'src/app/_services/order.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {PaymentService} from 'src/app/_services/payment.service';
import {MatDialog} from '@angular/material/dialog';
import {AlertsMessagesComponent} from 'src/app/alerts-messages/alerts-messages.component';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import { Store } from 'src/app/_models/store';
import { Permission } from 'src/app/_models/permission';
import { PermissionService } from 'src/app/_services/permission.service';
import { StoreService } from 'src/app/_services/store.service';
import { PinConfirmComponent } from 'src/app/orders/pin-confirm/pin-confirm.component';
@Component({
  selector: 'app-order-transaction-history',
  templateUrl: './order-transaction-history.component.html',
  styleUrls: ['./order-transaction-history.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class OrderTransactionHistoryComponent implements OnInit, OnDestroy {
  @Input() order: OrdersWithPayment;
  @Input() expanded = false
  @Input() fromData:any
  history: TransationHistory[];
  dataSource: TransactionHistoryDataSource;
  expandedElement;
  columnsToDisplay = ['updated_at', 'action', 'payment_type', 'amount', 'card_last_4',
  'name_on_card', 'gateway', 'terminal_id', 'transaction_id', 'button'];
  columnsToDisplayWithExpand;
  columnName = {
    'updated_at': 'Time',
    'action': 'Action',
    'payment_type': 'Payment Type',
    'amount': 'Amount',
    'card_last_4': 'Card Number',
    'name_on_card': 'Name on card',
    'gateway': 'Gateway',
    'terminal_id': 'Terminal ID',
    'transaction_id': 'Transaction ID',
    'button': ''
  }
  splitTranscations = [];
  private destroySubject: Subject<void> = new Subject();
  pinUser:any;
  storeid:any;
  permission: Permission;
  pinPermission: Permission;
  store: Store;
  constructor(private orderService: OrderService, private paymentService: PaymentService, public dialog: MatDialog,
  private permissionService: PermissionService,
  private storeService: StoreService
  ) { }
  ngOnInit() {
    this.storeid = localStorage.getItem('currentStore');
    this.permissionService.pinCurrentOperator.pipe(takeUntil
    (this.destroySubject)).subscribe((permission) => {
      this.permission = permission;
    });
    this.storeService.current.pipe(takeUntil
    (this.destroySubject)).subscribe(store => {
      if (store) {
        this.store = Object.assign(new Store(), store);
      }
    });
    if(this.order){
      this.columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
      this.dataSource = new TransactionHistoryDataSource(this.orderService);
      this.dataSource.loadHistory(this.order.bot_order.order_hash, this.fromData?.split_id);
    }
  }
  ngOnDestroy() {
    this.destroySubject.next();
  }
  hasRefund(element){
    return element.action === 'SALE' || element.action  === 'TIP_ADJUST' || element.action === 'ADJUST' || element.action === 'PAY_LATER' || element.action === 'REFUND' || element.action === 'VOID' || element.action === 'SPLIT CLOSED' || element.action === 'KEYED' || element.action === 'CANCELLED' || element.action === 'REFUND SUBMITTED' || element.action === 'VOID SUBMITTED';
  }
  void_txn(record){
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: 'Are you sure you want to void the transcation?',
        for_which: 'confirm'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let payload = {
          'order_id': this.order.bot_order.order_hash,
          'amount': record.amount,
          'history_id': record.id
        };
        this.orderService.processPostPayment(this.order.store.id, payload).pipe(takeUntil(this.destroySubject)).subscribe(data => {
          this.alertPopup('process_payment', 'Void successful.');
        }, error => {
          this.alertPopup('', 'Unable to void transaction');
        })
      }
    });
  }
  undo(element) {
    if (this.permission == null) {
      if (!this.store.hasModule('POS')) {
        this.undoProcessed(element);
      }
    } else if (this.permission.hasPermission('POS_FUNCTIONS', 'UNDO')) {
      this.undoProcessed(element);
    } else {
      const isMobile = window.innerWidth <= 599;
      const dialogRef = this.dialog.open(PinConfirmComponent, {
        width: isMobile ? '100vw' : '500px',
        height: isMobile ? '100vh' : '410px',
        maxWidth: isMobile ? '100vw' : '80vw',
        data: {}
      });
      dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(resultPer => {
        if (resultPer && resultPer.permission) {
          this.pinPermission = resultPer['permission'];
          if (this.pinPermission.hasPermission('POS_FUNCTIONS', 'UNDO')) {
            this.undoProcessed(element);
          } else {
            this.alertPopup('', 'This User/Manager does not have the required permission to perform this operation!');
          }
        }
      });
    }
  }
  undoProcessed(element) {
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: 'Are you sure you want to undo this transcation ?',
        for_which: 'confirm'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.pinUser = localStorage.getItem('pinUser');
        if (!this.pinUser) {
          this.pinUser = '';
        }
        const keyData = {
          history_id: element.id,
          order_id: this.order.bot_order.order_hash,
          payment_type: 'VOID',
          amount: element.amount,
          note: "undo",
          is_undo: true,
          user: this.pinUser
        };
        this.orderService.processPostPayment(this.storeid, keyData).subscribe(data => {
          this.orderService.loadClosedOpenSubject.next(true);
        }, (error) => {
        });
      }
    });
  }
  
  isDisabled(element: any): boolean {
    return element.refunded_amount !== null && 
           (element.refunded_amount > 0 || element.refunded_amount === 0.00);
  }
  alertPopup(name,msg) {
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: msg
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
      if (result) {
        if (name == 'process_payment') {
          this.ngOnInit();
        }
      }
    });
  }
}