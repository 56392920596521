import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {IOrder, IOrderItem} from '../../_interfaces/order';
import {OrderService} from '../../_services/order.service';
import {takeUntil} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import * as _ from 'lodash';
import {Store} from '../../_models/store';
import {PaymentOptionsDialogComponent} from '../payment-options-dialog/payment-options-dialog.component';
import {OccasionDetail, OrdersWithPayment} from 'src/app/_models/order';
import {OrderHelpers} from 'src/app/_helpers/helpers';
import {MyErrorStateMatcher} from 'src/app/_helpers/error-matcher';
import {StoreService} from 'src/app/_services/store.service';
import {TerminalService} from 'src/app/_services/terminal.service';
import {RefreshService} from 'src/app/_services/refresh.service';
import {CurrentOrderService} from 'src/app/_services/current-order.service';
import {POSOrderTabChangeService} from 'src/app/_services/pos-order-tab-change.service';
import {POSSummaryToggleService} from 'src/app/_services/pos-summary-toggle.service';
import {OrderTypeTabChangeService} from 'src/app/_services/order-type-tab-change.service';
import {CheckoutDialogComponent} from '../checkout-dialog/checkout-dialog.component';
import Utils from 'src/app/utils';
import {SignaturePadComponent} from '../signature-pad/signature-pad.component';
import {CardPaymentMqttComponent} from '../card-payment-mqtt/card-payment-mqtt.component';
import {ManualPrintControlComponent} from './manual-print-control/manual-print-control.component';
import {FrontFacingMqttService} from 'src/app/_services/front-facing-mqtt.service';
import {AlertsMessagesComponent} from 'src/app/alerts-messages/alerts-messages.component';
import {MatTabGroup} from '@angular/material/tabs';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {OfferV2} from '../../_models/campaign';
import { FloorPlanService } from 'src/app/_services/floor-plan.service';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent implements OnInit, OnDestroy {
  @Input() isOpenTab: Boolean = false;
  @Input() validUserData;
  @Input() store: Store;
  @ViewChild('tabs',{static: false}) tabGroup: MatTabGroup;

  @Output() toggleSummaryDrawer: EventEmitter<void> = new EventEmitter<void>();
  @Output() promoConditionsMetChange = new EventEmitter<boolean>();

  public form: UntypedFormGroup;
  public order: IOrder;
  private unsubscribe: Subject<null> = new Subject();

  public pizzaOptions: Map<string, string> = new Map(Object.entries({
    "ADD": "WHOLE",
    "H1": "LEFT",
    "H2": "RIGHT",
    "Half": "HALF",
    "Lite": "LITE",
    "Extra": "EXTRA",
    "No": "NO",
    "Remove": "NO"
  }));

  public showPromo = false;
  public showCustomTip = false;
  public subTotal = 0;
  public tax = 0;
  public total = 0;
  public logo;
  public quantityOpts = [0, 1, 2, 3, 4, 5];
  public terminalId;
  public specialRequest;
  public timeHours;

  public tips = [
    { name: '18%', value: 18 },
    { name: '20%', value: 20 },
    { name: '25%', value: 25 },
    { name: 'Cash', value: 0 }
  ];

  public terminals;

  serviceChargeOptions = [
    { "name": "12%", "value": 12 },
    { "name": "15%", "value": 15 },
    { "name": "18%", "value": 18 },
    { "name": "20%", "value": 20 },
  ]

  orderHash: string;
  orderWithPayment: OrdersWithPayment;
  isLoading: boolean = false;
  occasionDetail: OccasionDetail;

  errorMatcher = new MyErrorStateMatcher();
  orderName: string;
  orderInstruction: string;

  offers: OfferV2[];
  promoHidden = true;
  promoCode;
  promoCodeApplied;
  conditionsMet;
  posLoggedUser;
  terminalName;
  setOccasionDetail: any;
  getStoreOffersSub: Subscription;
  changeOrderSub: Subscription;
  pinUser: string;
  heightWindowResize: any;
  promoOrderHash: string;
  paymentCashSub : Subscription;

  @ViewChild('myInput') myInput: ElementRef;


  constructor(
    private orderService: OrderService,
    private storeService: StoreService,
    public dialog: MatDialog,
    private terminalService: TerminalService,
    private refreshService: RefreshService,
    private orderTabChangeService: POSOrderTabChangeService,
    private currentOrderService: CurrentOrderService,
    private orderTypeTabChangeService: OrderTypeTabChangeService,
    private posSummaryToggleService: POSSummaryToggleService,
    private ffmq : FrontFacingMqttService,
    private floorPlanService: FloorPlanService
  ) {
    // this.WindowResized();
  }

  ngOnInit() {
    if (localStorage.getItem('setOccasionDetail') == null) {
      localStorage.removeItem('order_hash')
    }
    this.refreshOrder();
    this.paymentCashSub =  this.ffmq.paymentMode.pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      console.log(data,"rakesh")
      if (data.type==="paymentTypeCash" || data.type === "paymentTypeCard"){
        this.openRegularCheckout()
      }
    })
    this.refreshService.current.pipe(takeUntil(this.unsubscribe)).subscribe(module => {
      //console.log(module);

      if (module === "POS_ORDER_SUMMARY") {
        this.refreshOrder();
      }
    })

    if (this.store) {
      this.orderService.getStoreLogo(this.store.id).pipe(takeUntil(this.unsubscribe)).subscribe(logo => {
        this.logo = logo;
      });
      this.loadOffers();
    }
    this.form = new UntypedFormGroup({
      phone: new UntypedFormControl(null),
      promo: new UntypedFormControl(null),
      tip: new UntypedFormControl(null),
      specialRequest: new UntypedFormControl(null),
      orderName: new UntypedFormControl(null),
      phoneNumber: new UntypedFormControl(""),
    });

    this.orderService.editSubmitSubject
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((item: IOrderItem) => {
        const index: number = item.index;
        delete item.index;

        this.order.items[index] = item;
        this.calculateTotals();
      });
    this.changeOrderSub = this.orderService.changeOrderSubject.pipe(takeUntil(this.unsubscribe)).subscribe(order => {
      //console.log(order);
      if (order) {
        localStorage.setItem('order_hash', order.order_hash);
        this.refreshOrder();
      } else {
        this.orderWithPayment = null;
        localStorage.removeItem('order_hash')
        this.refreshOrder();
      }
    });

    if(this.orderWithPayment){
      const serviceChargePercentOptions = this.orderWithPayment.getServiceChargePercentOptions();
      if (serviceChargePercentOptions.length > 0) {
        this.serviceChargeOptions = serviceChargePercentOptions.map(t => {
          return { "name": `${t.percent}%`, "value": t.percent }
        });
      }
    }

    this.refreshTerminal();
    this.isLoading = false;
  }

  onEnterKeyPressed() {
    this.myInput.nativeElement.blur();
  }
  // @HostListener('window:resize', ['$event'])
  // WindowResized(event?) {
  //   this.heightWindowResize = window.innerHeight;
  //   window.alert(`Window height '${this.heightWindowResize}' `)
  // }

  loadOffers() {
    //console.log("load offers");
    this.getStoreOffersSub = this.storeService.getStoreOffersV2(this.store.id).pipe(takeUntil(this.unsubscribe)).subscribe(offers => {
      this.offers = offers.data.filter(o => o.status === 'ACTIVE');
    })
  }

  refreshTerminal() {
    this.orderService.getTerminalId(this.store.id).pipe(takeUntil(this.unsubscribe))
      .subscribe((response) => {
        if (response.length > 0) {
          this.terminals = response;
          this.terminalId = response[0].terminal_id;
          this.terminalId = localStorage.getItem("selectedTerminal")
          this.terminalService.changeTerminal(this.terminalId)
        }
      });
  }

  refreshOrder() {
    //console.log(this.form);

    this.orderHash = localStorage.getItem("order_hash");
    if (this.orderHash != null && this.orderHash != "null" && typeof this.orderHash != 'undefined') {
      this.isLoading = true;

      if (this.orderHash) {
        this.orderService.getOrder(this.orderHash).pipe(takeUntil(this.unsubscribe)).subscribe(
          data => {
            this.orderWithPayment = OrderHelpers.parseOrderwithPayment(data);
            if (this.orderWithPayment && this.orderWithPayment.bot_order && this.orderWithPayment.bot_order.occasion) {
              this.currentOrderService.changeCurrentOrder(this.orderWithPayment);
              this.orderName = this.orderWithPayment.bot_order.order_name ? this.orderWithPayment.bot_order.order_name : null;
              this.orderInstruction = this.orderWithPayment.bot_order.special_request;
              this.store = this.orderWithPayment.store;
              this.occasionDetail = this.orderWithPayment.getOccasionDetail();
              this.promoCode = this.offers ? this.offers.find(o => o.promo_code === this.orderWithPayment.bot_order.promo_code) : null;
              this.promoCodeApplied = this.orderWithPayment.bot_order.promo_code.length > 0;
              this.promoHidden = !(this.orderWithPayment.bot_order.promo_code.length > 0);
              this.conditionsMet = +this.orderWithPayment.payment.promotional_discount > 0;
              if (this.promoCodeApplied) {
                this.promoConditionsMetChange.emit(this.conditionsMet);
              }

              if (!this.orderWithPayment.isPhoneDefault()) {
                this.form.controls.phoneNumber.setValue(Utils.getFormattedPhoneNumber(this.orderWithPayment.bot_order.phone_number));
              }

              this.isLoading = false;
              console.log('is loaduing');
              () => window.location.reload()

              this.form.controls.orderName.setValue(this.orderWithPayment.bot_order.order_name);
              this.loadOffers();
            } else {
              console.log("orderWith payment null");

            }


          })
      }

    }
  }

  cancelOrderConfirmation() {
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: 'Are you sure you want to delete this order?',
        for_which: 'confirm'
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      if (result) {
        this.cancelOrder();
      }
    });
  }

  cancelOrder() {
    if (this.occasionDetail.occasion == "DINE-IN" || this.occasionDetail.occasion == "TABLESIDE") {
      this.orderService.tableUpdate.next(true);
    }
    localStorage.removeItem('order_hash')
    if (this.occasionDetail != null) {
      this.setOccasionDetail = this.occasionDetail;
    }
    this.orderWithPayment = null;
    if (this.store.pos_setting && !this.store.pos_setting["default_load"]) {
      this.occasionDetail = null;
    }
    this.orderName = null;
    console.log('this.fomr1111111111111111111 222222 3333333333333',this.form);

    if (this.form != undefined) {
      this.form.controls.orderName.setValue(null);
      this.form.controls.phoneNumber.setValue('');
      this.form.reset();
    }
    this.orderService.orderDoneSubject.next();
    this.orderService.keyboardValueSet.next(false);
    this.floorPlanService.floorCreatedUpdate.next(false);

    localStorage.removeItem('setOccasionDetail')
  }



  updatePhoneNumber(phoneNumber: string, orderName: string = '') {
    if (Utils.getPlainPhoneNumberFromString(phoneNumber).length === 12) {

      const plainPhoneNumber = Utils.getPlainPhoneNumberFromString(phoneNumber);
      const finalOrderName = orderName ? orderName : this.orderWithPayment.bot_order.order_counter;
      this.orderService.updatePhoneNumber(this.orderHash, plainPhoneNumber, finalOrderName, true).pipe(takeUntil(this.unsubscribe)).subscribe(() => {
        this.refreshOrder();
      })
    }
  }

  // setNewOrder() {
  //   this.orderService.newOrderSubject
  //     .pipe(takeUntil(this.unsubscribe))
  //     .subscribe((newOrder: IOrder) => {
  //       this.order = {...newOrder};
  //       console.log(this.order);
  //       const orderHash = localStorage.getItem('order_hash');
  //       this.orderService.getOrderByHash(orderHash)
  //         .pipe(takeUntil(this.unsubscribe))
  //         .subscribe((res) => {
  //           this.timeHours = res.bot_order.occasion_schedule;
  //         });

  //         // this.orderService.updateOccasionSchedule(orderHash, {
  //         //     occasion: this.order.occasion,
  //         //     time: this.order.time,
  //         //     occasion_schedule: this.order.schedule,
  //         //     dine_in_table_number: this.order.tableId,
  //         //     delivery_address: this.order.address,
  //         //     curbside_vehicle_id: this.order.curbSide
  //         //   }
  //         // ).pipe(takeUntil(this.unsubscribe))
  //         // .subscribe((res) => {
  //         //   this.order.schedule = res;
  //         // });
  //       if (this.order && Object.keys(this.order).length && this.order.items && this.order.items.length) {
  //         this.calculateTotals();
  //       }
  //     });

  // }

  updateItemQuantity(menuItemId: string) {
    const item = this.order.items.find((orderItem: IOrderItem) => orderItem.id === +menuItemId);
    if (!item.quantity) {
      this.deleteItem(menuItemId, item);
    } else {
      this.calculateTotals();
    }
  }

  // editItem(orderItem: IOrderItem, index: number) {
  //   orderItem.index = index;
  //   // this.orderService.editOrderSubject.next(orderItem);
  // }

  deleteItem(menuItemId: string, i) {
    const filteredItems: IOrderItem[] = [...this.order.items]
      .filter((orderItem: IOrderItem) => orderItem.id !== +menuItemId);

    this.orderService.newOrderSubject.next({ ...this.order, items: filteredItems });

    this.calculateTotals();
    return filteredItems.splice(i, 1);
  }

  setTip(value: number, tipType: 'pcg' | 'value') {
    this.order.tip = value;
    this.order.tipType = tipType;

    this.calculateTotals();
  }

  calculateTotals() {
    this.total = 0;
    this.subTotal = _.sum(this.order.items.map((item: IOrderItem) => item.price * item.quantity));

    const tip: number = this.order.tip;
    if (tip) {
      if (this.order.tipType === 'pcg') {
        this.total = (this.subTotal * tip / 100);
      } else {
        this.total = (this.subTotal + tip);
      }
    }

    this.total += this.subTotal + this.tax;
  }

  printQuantity(modifierDetails) {
    if (typeof modifierDetails.hasQuantity === 'number') {
      return modifierDetails.hasQuantity;
    }
    return modifierDetails.hasQuantity[modifierDetails.id];
  }

  printDirection(modifierDetails) {
    if (typeof modifierDetails.direction === 'string') {
      return modifierDetails.direction;
    }
    return modifierDetails.direction[modifierDetails.id];
  }

  openDialog() {
    console.log(this.store);
    if (this.store.enable_consumer_choice) {
      this.openConsumerChoiceDialog();
    } else if (this.store.pos_payment_gateway === 'NMI_BBPOS') {
      this.testWs();
    } else {
      this.openRegularCheckout();
    }
  }

  openRegularCheckout() {
    const orderHash = localStorage.getItem('order_hash');

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const isMobile = window.innerWidth <= 470;
    const dialogRef = this.dialog.open(CheckoutDialogComponent, {
      // width: '630px', height: '630px',
        width: isMobile ?'100vw' : '630px',
        height: isMobile ? 'auto' : '630px',
        maxWidth: isMobile ? '100vw' : '',
      disableClose: true,
      data: {
        orderWithPayment: this.orderWithPayment,
        terminalId: this.terminalId,
        total: this.orderWithPayment.payment.total,
        cashTotal: this.orderWithPayment.payment.cash_due_total,
        subTotal: this.orderWithPayment.payment.sub_total,
        from : "business"
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(isComplete => {
    });
  }

  openConsumerChoiceDialog() {
    const orderHash = localStorage.getItem('order_hash');
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const isMobile = window.innerWidth <= 470;
    const dialogRef = this.dialog.open(PaymentOptionsDialogComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      disableClose: true,
      data: {
        command: 'SALE',
        order_id: this.orderHash,
        orderWithPayment: this.orderWithPayment,
        terminalId: this.terminalId,
        total: this.orderWithPayment.payment.total,
        subTotal: this.orderWithPayment.payment.sub_total,
        cashier_id: localStorage.getItem('posLoggedUser'),
        terminal_name: localStorage.getItem('selectedTerminalName')
      },
      panelClass: 'cash-card-checkout',
    });

    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(isComplete => {
      console.log(isComplete);

      if (isComplete) {
        this.cancelOrder();
      }

    });
  }

  updateInstruction() {
    const orderHash = localStorage.getItem('order_hash');
    let orderName = this.form.controls.orderName.value
    orderName = orderName ? orderName : this.orderWithPayment.bot_order.order_counter;

    let instruction = this.form.controls.specialRequest.value;

    this.orderService.updateInstruction(orderHash, {
      special_request: instruction,
      order_name: orderName
    })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((instruction) => {
        console.log(instruction);
        this.specialRequest = this.form.controls.specialRequest.value;
        this.specialRequest = instruction;
      });
  }

  timeout: any = null
  updateOrderName(event) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.updateInstruction();
      }
    }, 2000);
  }

  timeout1: any = null
  updateSpecialRequest(event) {
    clearTimeout(this.timeout1);
    var $this = this;
    this.timeout1 = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.updateInstruction();
      }
    }, 2000);
  }


  togglePromoCode() {
    this.promoHidden = !this.promoHidden;
  }

  applyPromoCode(code) {
    if (code === 'REMOVE') {
      this.removePromoCode();
      this.promoHidden = true;
    } else {
      this.promoCode = code;
      if (this.orderHash != undefined) {
        this.promoOrderHash = this.orderHash;
      } else {
        this.promoOrderHash = localStorage.getItem('order_hash');
      }
      this.orderService.applyPromoCode(this.promoOrderHash, code).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
        this.promoConditionsMetChange.emit(this.promoCodeApplied && this.conditionsMet);
        this.refreshOrder();
      }, error => {
        this.promoCode = null;
        this.alertPopup('Invalid Promo Code Entered!');
        // alert('Invalid Promo Code Entered!');
      })
    }
  }

  removePromoCode() {
    this.orderService.removePromoCode(this.orderWithPayment.bot_order.order_hash).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.promoCode = null;
      this.promoConditionsMetChange.emit(true);
      this.refreshOrder()
    })
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    if (this.getStoreOffersSub) {
      this.getStoreOffersSub.unsubscribe();
    }

    if (this.changeOrderSub) {
      this.changeOrderSub.unsubscribe();
    }

    if (this.paymentCashSub){
      this.paymentCashSub.unsubscribe()
    }
  }

  payLater() {
    if (this.orderWithPayment.store['feature_flag']['manual_print_pay_later']) {
      const isMobile = window.innerWidth <= 470;
      const dialogRef = this.dialog.open(ManualPrintControlComponent, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        data: {
          hasBack: true,
          value: 1,
          order_hash: this.orderWithPayment.bot_order.order_hash,
          key: 'pay_later_print'
        }
      });
      dialogRef.afterClosed().subscribe((selectVal: boolean) =>{
        if(selectVal){
          this.paylaterCall();
        }
      });
    } else {
      this.paylaterCall();
    }
  }
  paylaterCall() {
    this.posLoggedUser = localStorage.getItem("posLoggedUser");
    this.terminalName = localStorage.getItem('selectedTerminalName');
    this.pinUser = localStorage.getItem('pinUser');
    const payload = {
      'cash_total': this.orderWithPayment.payment.cash_due_total,
      'terminal_id': this.terminalName,
      'cashier_id': this.posLoggedUser,
      'pin_user': this.pinUser
    }
    this.orderService.payLater(this.orderHash, payload).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.cancelOrder();
    })
  }

  edit() {
    this.setOccasionDetail = this.occasionDetail;
    console.log(this.setOccasionDetail);
    localStorage.setItem('setOccasionDetail', JSON.stringify(this.setOccasionDetail));
    if (this.validUserData && this.store.hasModule('POS')) {
      this.currentOrderService.changeCurrentOrder(this.orderWithPayment);
      this.orderTypeTabChangeService.changeTab(0);
      this.orderTabChangeService.changeTab(2);
      this.posSummaryToggleService.toggle('OPEN')
    } else {
      this.orderTypeTabChangeService.changeTab(0);
      this.alertPopup('Please login and select the station');
      // alert("Please login and select the station")
    }
  }

  applyServiceCharge(change: any) {
    this.orderService.updateServiceCharge(this.orderHash, change.value).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
     if(this.orderHash) {this.orderService.getOrderByHash(this.orderHash).pipe(takeUntil(this.unsubscribe)).subscribe(ord => {
        this.orderWithPayment.updateServiceCharge(+ord.payment.pos_service_charge)
        localStorage.setItem("order_hash", this.orderHash);
        this.refreshOrder();
      });
    }})
  }

  testWs() {
    console.log('WS implementation');
    let data = {
      terminal_id: localStorage.getItem('selectedTerminal'),
      command: 'SALE',
      order_id: this.orderHash,
      amount: this.orderWithPayment.getTotal(),
      orderWithPayment: this.orderWithPayment,
      terminalId: this.terminalId,
      total: this.orderWithPayment.payment.total,
      cashTotal: this.orderWithPayment.payment.cash_due_total,
      subTotal: this.orderWithPayment.payment.sub_total,
      payment_type: 'CREDIT',
      is_open_tab: false,
      cashier_id: localStorage.getItem('posLoggedUser'),
      pin_user: localStorage.getItem('pinUser'),
      terminal_name: localStorage.getItem('selectedTerminalName')
    };
    let dialogRef = this.dialog.open(CardPaymentMqttComponent, {
      width: '630px', data: data, disableClose: true,
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      if (result) {
        this.cancelOrder();
      }
    });
  }

  openSignPad(){
    const dialogRef = this.dialog.open(SignaturePadComponent);
  }

  alertPopup(msg) {
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: msg
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {

    });
  }
}
