import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from 'src/app/_services/user.service';
import {Store} from 'src/app/_models/store';
import {Subscription} from 'rxjs';
import {StoreService} from 'src/app/_services/store.service';
import {POSService} from 'src/app/_services/pos-service';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationComponent} from '../confirmation/confirmation.component';
import {AlertsMessagesComponent} from 'src/app/alerts-messages/alerts-messages.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-time-tracker',
  templateUrl: './time-tracker.component.html',
  styleUrls: ['./time-tracker.component.scss']
})
export class TimeTrackerComponent implements OnInit, OnDestroy {

  input = "";
  result = null;
  allUserPins: any = [];
  allUserData: any;
  getUserPin: any;
  store: Store;
  storeSub: Subscription;
  getLoginUser: any;
  selectedTerminal: string;
  errorMessage = false;
  confirmationMsg: string;
  userObject: any;
  currentRecordId: any;
  currentRecordClockInTime: any;
  currentRecordClockOutTime: any;
  currentRecordStartBreakTime: any;
  currentRecordEndBreakTime: any;
  userLoggedIn: any;
  clockedIn = false;
  clockedOut = false;
  breakString = "START BREAK";
  numberOfBreaks = 0;
  confirmationSubject: Subscription;
  isSmallScreen: any;
  constructor(
    private userService: UserService,
    private storeService: StoreService,
    private posService: POSService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {
    this.storeSub = this.storeService.current.subscribe((store) => {
      if (store) {
        this.store = Object.assign(new Store(), store);
        this.login(0);
      }
    });
  }

  ngOnInit() {
    this.login(0);
    this.confirmationSubject = this.userService.getUserList.subscribe(result => {
      console.log('Result', result);

      if (result) {
        this.clockInConfirmation();
      }
    })

    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      this.isSmallScreen = result.matches;
    });
  }

  ngOnDestroy() {
    if (this.confirmationSubject) {
      this.confirmationSubject.unsubscribe();
    }
  }

  pressNum(num: string) {
    const updatedText = this.input + num;
    if (updatedText.length === 5) {
      return;
    }
    this.input = this.input + num;
    this.calcAnswer();
  }

  calcAnswer() {
    const formula = this.input;
    this.result = formula;
    if(this.input.length > 3){
      this.userLoggedIn = this.loginUser();
      if(this.userLoggedIn){
        const d = this.currentTimeRecord().toPromise().then(
          result => {
            this.currentRecordId = result['id'];
            this.numberOfBreaks = result['number_of_breaks'];
            if(result['clock_in_time']){
              this.currentRecordClockInTime = result['clock_in_time'];
              this.clockedIn = true;
              if(result['start_break_time']){
                this.currentRecordStartBreakTime = result['start_break_time'];
                this.breakString = "END BREAK";
              }
              if(result['end_break_time'] && result['end_break_time'] > result['start_break_time']){
                this.currentRecordEndBreakTime = result['end_break_time'];
                this.breakString = "START BREAK";
              }
              if(result['clock_out_time']){
                this.currentRecordClockOutTime = result['clock_out_time'];
                this.clockedIn = false;
                if(this.currentRecordClockInTime > this.currentRecordClockOutTime){
                  this.clockedIn = true;
                }
              }
            }
          }
        );
      }
    }
  }

  allClear() {
    this.result = "";
    this.input = "";
    this.errorMessage = false;
  }

  clear() {
    if (this.input !== "") {
      this.input = this.input.substr(0, this.input.length - 1);
      if (!this.input) {
        this.result = null;
        return;
      }
      this.calcAnswer();
    }
  }

  clockIn() {
    if(this.userLoggedIn){
      this.login(1);
    }
  }

  clockInConfirmation() {
    const dialogConfig = {
      width: this.isSmallScreen ? '100%' : '663px',
      height: this.isSmallScreen ? '413px' : '340px',
      maxWidth: this.isSmallScreen ? '100%' : '80vw',
      data: {
        'pk': this.currentRecordId,
        'userObject': this.userObject,
        'action': "clock-in",
        'numberOfBreaks': this.numberOfBreaks,
        'store_id': this.store.id
      }
    };

    const dialogRef = this.dialog.open(ConfirmationComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.userService.getUserList.next(false);
      this.allClear();
      this.ngOnInit();
    });
  }

  clockOut() {
    if(this.userLoggedIn){
      const dialogConfig = {
        width: this.isSmallScreen ? '100%' : '663px',
        height: this.isSmallScreen ? '413px' : '340px',
        maxWidth: this.isSmallScreen ? '100%' : '80vw',
        data: {
          'pk': this.currentRecordId,
          'userObject': this.userObject,
          'action': "clock-out",
          'numberOfBreaks': this.numberOfBreaks,
          'store_id': this.store.id
        }
      };

      const dialogRef = this.dialog.open(ConfirmationComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        this.allClear();
        this.ngOnInit();
        this.clockedIn = false;
      });     
    }
  }

  punchBreak() {
    if(this.userLoggedIn){
      const dialogConfig = {
        width: this.isSmallScreen ? '100%' : '663px',
        height: this.isSmallScreen ? '413px' : '340px',
        maxWidth: this.isSmallScreen ? '100%' : '80vw',
        data: {
          'pk': this.currentRecordId,
          'userObject': this.userObject,
          'action': this.breakString,
          'numberOfBreaks': this.numberOfBreaks,
          'store_id': this.store.id
        }
      };
  
      const dialogRef = this.dialog.open(ConfirmationComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        this.allClear();
        this.ngOnInit();
      });
    }
  }

  currentTimeRecord() {
    this.login(0);
    this.loginUser();
    const payload = {
      'user': this.userObject['id'],
      'store': this.store.id
    }
    return this.userService.getCurrentRecord(payload).pipe((data) => {
      return data;
    });
  }

  login(e) {
    const storeId = this.store ? this.store.id : localStorage.getItem("currentStore");
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.getUserPin = this.userService
      .getAllUser(storeId, user["username"])
      .subscribe((data) => {
        this.allUserData = data;
        if (e == 1) {
          this.userService.getUserList.next(true);
        }
        this.allUserPins = data.map((item) => {
          return item["user_profile"].pin;
        });
      });
  }

  loginUser() {
    const validPin = this.allUserPins.includes(this.result.toString());
    if (validPin) {
      const userObject = this.allUserData.find((user) => {
        return user["user_profile"].pin == this.result.toString();
      });
      this.userObject = userObject
      this.posService.validUserPin = true;
    } else {
      let dialogRef = this.dialog.open(AlertsMessagesComponent, {
        disableClose: true,
        width: '364px',
        minHeight: '20vh',
        data: {
          message: this.input + ' : Incorrect PIN! Please retry after pressing clear button.'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result) {
          this.errorMessage = true;
        }
      });
      // alert(this.input + ' : Incorrect PIN! Please retry after pressing clear button.' );
    }
    return validPin;
  }
}
