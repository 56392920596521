import {Component, ElementRef, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {BusinessService} from '../business.service';
import {Store} from '../_models/store';
import {StoreService} from '../_services/store.service';
import {AuthenticationService} from '../_services/authentication.service';
import {Router} from '@angular/router';
import {UnreadService} from '../_services/unread-message.service';
import {debounceTime, switchMap, takeUntil, tap} from 'rxjs/operators';
import {PermissionService} from '../_services/permission.service';
import {DOCUMENT} from '@angular/common';
import {TerminalStatusService} from '../_services/terminal-status.service';
import {RefreshService} from '../_services/refresh.service';
import {Modules} from '../constants';
import {Subject, Subscription} from 'rxjs';
import {OrderWebsocketService} from '../_services/websocket.service';
import {IMqttMessage, IPublishOptions, MqttService} from 'ngx-mqtt';
import {IClientSubscribeOptions} from 'mqtt/types/lib/client-options';
import {DigitalDisplayService} from '../_services/digital-display.service';
import {FrontFacingMqttService} from '../_services/front-facing-mqtt.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { POSService } from '../_services/pos-service';


@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.scss']
})
export class HomeLayoutComponent implements OnInit, OnDestroy {
  opened: boolean = true;
  stores;
  selectedStoreName
  store;
  toolbarForm;

  filteredStores;
  filter = new UntypedFormControl();
  contentMargin = 257;
  errorMsg;
  isLoading;
  elem;
  isFullScreen = false;

  statusSocket;
  terminalId;
  isOnline;
  isPing = false;

  statusSubs;
  statusSocketSubject;
  soundOpenCLose = false;
  isOrdersView: boolean;
  idStore: any;
  lost: boolean;
  isSocketActive = false;
  isDeviceActive = false;
  onMob: Subscription;

  retryInterval;
  retrying = false;
  private orderAccept: Subscription;
  private subscription: Subscription;
  private destroySubject: Subject<void> = new Subject();
  private sideNav: Subscription;
  pingIntervalId;
  mqtSub;
  alertSound;
  fullScreenUpdateSub: Subscription;
  onscrollTop: Subscription;
  @HostListener("window:beforeunload")
  canDeactivate() {
    this.closeFullscreen();
  }

  constructor(
    private businessService: BusinessService,
    private storeService: StoreService,
    private unreadService: UnreadService,
    private authService: AuthenticationService,
    private router: Router,
    private permissionService: PermissionService,
    private terminalStatusService: TerminalStatusService,
    private refreshService: RefreshService,
    @Inject(DOCUMENT) private document: any,
    private _snackBar: MatSnackBar,
    private orderWebsocketService: OrderWebsocketService,
    private mqttService: MqttService,
    private  DigitalDisplay: DigitalDisplayService,
    private mq: FrontFacingMqttService,
    private posService: POSService
  ) {
    this.orderAccept = this.storeService.isOrderView.pipe(takeUntil(this.destroySubject)).subscribe(value => {
      this.isOrdersView = value;
    });

    this.sideNav = this.storeService.sideNavOpenClose.pipe(takeUntil(this.destroySubject)).subscribe(value => {
      this.opened = value;
      this.toggleSideNav(1);
    });

    this.fullScreenUpdateSub = this.storeService.fillScreenUpdateFromPOS.pipe(takeUntil(this.destroySubject)).subscribe(value => {
      this.isFullScreen = value;
      this.toggleSideNav(2);

    });
    if (window.innerWidth <= 459) {
      this.onMob = this.posService.posMenuHeight.pipe(takeUntil(this.destroySubject)).subscribe(data => {
        if (data == 'full-screen') {
          const sidenavContent = document.querySelector('.mat-sidenav-container') as HTMLElement;
          if (sidenavContent) {
            sidenavContent.style.paddingTop = `0px`;
          }
        } else if (data == 'close-screen') {
          const sidenavContent = document.querySelector('.mat-sidenav-container') as HTMLElement;
          if (sidenavContent) {
            sidenavContent.style.paddingTop = `60px`;
          }
          const topHeader = document.querySelector('.mat-toolbar') as HTMLElement;
          if (topHeader) {
            topHeader.style.visibility = 'visible';
          }
        }
      });
    }

    this.onscrollTop =  this.posService.scrolltoTop.pipe(takeUntil(this.destroySubject)).subscribe(data => {
      if (data) {
        this.scrollToTop();
      }
    });

  }

  scrollToTop(): void {
    console.log('TO TOP');
    const drawerContents = document.querySelectorAll('.mat-drawer-content') as NodeListOf<HTMLElement>;
    drawerContents.forEach(container => {
      container.scrollTop = 0;
    });
  }

  ngOnDestroy(): void {
    if (this.statusSubs) {
      this.statusSubs.unsubscribe();
    }

    if (this.statusSocketSubject) {
      this.statusSocketSubject.unsubscribe();
    }

    if (this.orderAccept) {
      this.orderAccept.unsubscribe();
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.sideNav) {
      this.sideNav.unsubscribe();
    }
    this.destroySubject.next();
    if (this.onMob) {
      this.onMob.unsubscribe();
    }

    if (this.onscrollTop) {
      this.onscrollTop.unsubscribe();
    }
  }

  ngOnInit() {
    this.elem = document.documentElement;
    this.opened = (window.innerWidth <= 1024) ? false : true;
    this.toolbarForm = new UntypedFormGroup({
      store: new UntypedFormControl()
    });

    this.mqttService.onConnect.pipe(takeUntil(this.destroySubject)).subscribe(x => {
      this.isSocketActive = true;
      console.log('Connection established successfully.', 'OK', { duration: 2000 });
    });

    this.mqttService.onReconnect.pipe(takeUntil(this.destroySubject)).subscribe(x => {
      console.log('Connection to the server is lost, retrying to establish a connection in 5 seconds.');
    });

    this.mqttService.onClose.pipe(takeUntil(this.destroySubject)).subscribe(x => {
      console.log('Connection to the server closed.');
      this.isSocketActive = false;
    });

    this.mqttService.onMessage.pipe(takeUntil(this.destroySubject)).subscribe(x => {
      console.log('received message');
    });

    this.filter.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.stores = [];
          this.isLoading = true;
        }),
        switchMap(value => this.businessService.getStores(value))
      ).pipe(takeUntil(this.destroySubject))
      .subscribe(data => {
        this.stores = data.map(store => Object.assign(new Store(), store));
        console.log(this.stores);

    });

    this.businessService.getStores().pipe(takeUntil(this.destroySubject))
      .subscribe(data => {
        this.stores = data.map(store => Object.assign(new Store(), store));
        if (!localStorage.getItem("currentStore")) {

          this.store = this.stores[0];
          localStorage.setItem("currentStore", this.store.id);
          localStorage.setItem("storeTimezone", this.store.time_zone);
        } else {
          let currentStoreId = localStorage.getItem("currentStore")
          let store = this.stores.filter(store => store.id === +currentStoreId);

          if (store) {
            this.store = store[0];
          } else {
            this.store = this.stores[0];
          }
        }
        if (this.store) {
          this.selectedStoreName = this.store.store_name;
          this.storeService.changeStore(this.store);
          this.unreadService.unreadMessages(this.store.id);
          this.permissionService.getUserPermission(this.store.id);
          this.idStore = this.store.id;
          this.initMqtt();
          if (this.store.feature_flag.auto_full_screen_mode) {
            if (window.innerWidth > 768) {
                this.openFullscreen();
            }
          } else {
            if (this.isFullScreen) {
              this.closeFullscreen();
            }
          }
        }

        if (!localStorage.getItem("store_type")) {
          this.store = this.stores[0];
          localStorage.setItem("store_type", this.store.store_type);
        }
    });

    // this.initializeDatabase();
  }

  // private async initializeDatabase(): Promise<void> {
  //   const currentStoreId = localStorage.getItem("currentStore")
  //   await this.rxdbService.createDatabase(currentStoreId);
  // }

  storeSelected(store: Store) {
    console.log(store);
    setTimeout(() => {
    localStorage.removeItem("order_hash");
    localStorage.removeItem("currentPIN");
    this.store = store
    this.selectedStoreName = store.store_name
    let currentStoreId = localStorage.getItem("currentStore")
    if (store.id != currentStoreId) {
      this.router.navigate(['home', 'info'])
    }
    this.storeService.changeStore(store)
    setTimeout(() => {
      const stre = {
        store_data: store,
        number: 1
      }
      this.storeService.isStoreChange.next(stre);
    },1000);
    this.unreadService.unreadMessages(store.id)
    setTimeout(() => {
      console.log('Function Calling Successfully!!!!!!!!!!!!!!!!!!');

      this.initMqtt();
    }, 2000)
    if (this.store.feature_flag.auto_full_screen_mode) {
      if (window.innerWidth > 768) {
          this.openFullscreen();
      }
    } else {
      if (this.isFullScreen) {
        this.closeFullscreen();
      }
    }
    console.log(this.selectedStoreName);
    this.DigitalDisplay.unreadMessages(store.id);
      this.ngOnInit();
    }, 500);
  }

  storeName(store) {
    if (store) {
      return store.store_name;
    } else {
      return '';
    }
  }

  logout() {
    this.closeFullscreen();
    this.authService.logout()
    this.router.navigate(['/login'])
  }

  isSmallScreen() {
    return window.innerWidth < 1000 ? true : false;
  }

  toggleSideNav(e) {
    this.opened = !this.opened;
    localStorage.setItem('side-nav', `${this.opened}`);
    this.contentMargin = this.isSmallScreen() ? 0 : (this.opened ? 257 : 65);

    const sidenav = document.querySelector('.mat-sidenav') as HTMLElement;
    if (sidenav) {
      sidenav.classList.remove('fullscreen-mode');
      if (!this.opened && e == 1) {
        sidenav.classList.add('snav-full-closed');
        this.contentMargin = 0;
      } else {
        if (sidenav.classList.contains('snav-full-closed')) {
          sidenav.classList.remove('snav-full-closed');
        }
      }
      if (e == 2) {
        this.opened = true
      }
    }
    const sidenavContent = document.querySelector('.mat-drawer-content') as HTMLElement;
    if (sidenavContent) {
      sidenavContent.classList.remove('fullscreen-mode');
      sidenavContent.style.marginLeft = `${this.contentMargin}px`;
    }
  }

  dummy() { }

  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
    const topHeader = document.querySelector('.mat-toolbar') as HTMLElement;
    if (topHeader) {
      topHeader.style.visibility = 'hidden';
    }
    const appContainer = document.querySelector('.app-container') as HTMLElement;
    if (appContainer) {
      appContainer.style.top = '0px';
    }
    this.isFullScreen = true;
    this.storeService.fullScreenUpdate.next(true);
    this.posService.posMenuHeight.next('full-screen');
    // this.toggleSideNav();
  }

  /* Close fullscreen */
  closeFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
    document.body.removeEventListener('click', this.closeFullscreen);
    const topHeader = document.querySelector('.mat-toolbar') as HTMLElement;
    if (topHeader) {
      topHeader.style.visibility = 'visible';
    }
    // const appContainer = document.querySelector('.app-container') as HTMLElement;
    // if (appContainer) {
    //   appContainer.style.top = '0px';
    // }
    // document.body.removeEventListener('click', this.closeFullscreen);
    this.isFullScreen = false;
    this.storeService.fullScreenUpdate.next(false);
    this.posService.posMenuHeight.next('close-screen');
  }

  check() {
    console.log(this.isOnline);
  }

  publish(payload: string) {
    const qos = 2;
    const terminalId = localStorage.getItem('selectedTerminal');
    this.mqttService.unsafePublish(`/link/${terminalId}/sub`, payload, { qos } as IPublishOptions);
  }


  reconnectMqttServer() {
    this.mqttService.connect();
    const payload = {
      command: 'RECONNECT'
    };
    this.publish(JSON.stringify(payload));
  }


  initMqtt() {
    let currentStoreId = localStorage.getItem("currentStore");
    this.mqtSub = this.mqttService.observe(`/order/${currentStoreId}/sub`,
      { qos: 1 } as IClientSubscribeOptions).pipe(takeUntil(this.destroySubject)).subscribe((message: IMqttMessage) => {
        const orderResponse = JSON.parse(message.payload.toString());
        const aa = orderResponse.bot_order;
        if (aa.view_notified == false && aa.is_pos == false) {
          this.storeService.isOrderView.next(true);
          this.businessService.orderSet.next(orderResponse);
        } else {
          this.businessService.orderSet.next(orderResponse);
          this.storeService.isOrderView.next(false);
        }

        if (aa.is_tab_open == true) {
          this.refreshService.refreshModule(Modules.OPEN_TAB);
        } else {
          this.refreshService.refreshModule(Modules.CLOSED_TAB);
        }
      })
  }
}
