import { Component, ElementRef, Input, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { Chart } from 'chart.js';
import { MenuService } from 'src/app/_services/menu.service';
@Component({
  selector: 'app-menu-piechart',
  templateUrl: './menu-piechart.component.html',
  styleUrls: ['./menu-piechart.component.scss']
})
export class MenuPiechartComponent implements OnInit, OnDestroy, AfterViewInit {
  chart: any;
  @ViewChild('dChart', { static: false }) dChart: ElementRef;
  @Input() chartLabels: any = [];
  jsonArray: any = [];
  @Input() cutOut: number = 85;
  backgroundColors: any = [];
  graphTimeout: any;
  constructor(public menuService: MenuService) {
    this.menuService.getRefresh().subscribe((value: any) => {
      if (this.chart) this.chart.destroy();
      if (value) {
        this.backgroundColors = value.colors;
        this.jsonArray = value.numbers;
        if (value.isUpdate == 1) {
          this.updateGraph();
        }
      }
    });
  }
  ngOnInit() {
    this.graphTimeout = setTimeout(() => {
      this.updateGraph();
    }, 3000);
  }
  ngAfterViewInit() {
    window.addEventListener('resize', this.onResize);
    this.onResize(); // Initial call to set size
  }
  updateGraph() {
    if (!this.dChart) return;
    const cvs: any = this.dChart.nativeElement;
    this.chart = new Chart(cvs, {
      type: 'doughnut',
      data: {
        labels: this.chartLabels,
        datasets: [
          {
            data: this.jsonArray,
            backgroundColor: this.backgroundColors,
            fill: false,
            borderWidth: 0,
          },
        ],
      },
      options: {
        responsive: true, // Ensuring the chart is responsive
        maintainAspectRatio: false, // Allows the canvas to adapt its aspect ratio
        legend: {
          display: false,
        },
        cutoutPercentage: this.cutOut,
        tooltips: {
          enabled: false
        }
      },
    });
  }
  ngOnDestroy(): void {
    clearTimeout(this.graphTimeout);
    window.removeEventListener('resize', this.onResize);
    if (this.chart) this.chart.destroy();
  }
  onResize = () => {
    if (this.chart) {
      this.chart.resize();
    }
  }
}