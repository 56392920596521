import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DigitalDisplayService } from 'src/app/_services/digital-display.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: "app-add-master-category-dialog",
  templateUrl: "./add-master-category-dialog.component.html",
  styleUrls: ["./add-master-category-dialog.component.scss"],
})
export class AddMasterCategoryDialogComponent implements OnInit {
  addMasterCategoryForm: UntypedFormGroup;
  isLoading = false;
  // newScreen: boolean;
  // hideCategory: boolean;
  storeId: any;
  subcategoryList: any;
  screen: boolean;
  hideCategory: boolean;
  subCategoryId: any = [];
  storeAll: any;
  storeCheck: any;
  addMasterCategoryAllStoreForm: UntypedFormGroup;
  hideProductWeight: boolean = false;
  emoji: any;
  subcategory_list_data: any;
  searchEmoji;
  showModifierGroupSelete: any = [];
  searchModifierGroup:any;
  searchSubCategory:any;
  constructor(
    public fb: UntypedFormBuilder,
    private digitalDisplayService: DigitalDisplayService,
    public dialogRef: MatDialogRef<AddMasterCategoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.storeId = localStorage.getItem("currentStore");
    this.subcategoryListAll();
    this.ModifierGroupListAll();
    this.emojiList();
    this.subcategoryList = this.data.data;
    this.storeAll = this.data;
    this.storeCheck = this.data.store;
    // for (let i = 0; i < this.subcategoryList.length; i++) {
    //   const element = this.subcategoryList[i].id;
    //   this.subCategoryId.push(element).value;
    // }
    // console.log("list 1111111111", this.subCategoryId);

    this.screen = false;
    this.hideCategory = false;
    if (this.storeCheck == 'Store1') {
      this.addMasterCategoryForm = this.fb.group({
        name: ["", Validators.required],
        priority: [""],
        new_screen: [""],
        hide_category: [""],
        categorys: [""],

      });
    }
    else if (this.storeAll == 'allStore') {
      this.addMasterCategoryAllStoreForm = this.fb.group({
        store_id: [],
        name: ["", Validators.required],
        priority: ["1"],
        modifiergroups: [""],
        hide_mastercategory: [""],
        new_screen: [""],
        subcategory: [""],
        font_color: ["#FFFFFF"],
        // hide_product_weight: [""],
        emoji: [""],
        emoji_search: [""],
        font_size: [""],
        alignment: [""],
        modifierGroup_search:[],
        SubCategory_search:[],
        modifiergroups_subcat_alignment: [""],
        single_pricing_alignment: [""],
        price_format: [""],
        modifiergroups_subcat_font_size: [""],
        modifiergroups_subcat_font_color: [""],
        modifiergroups_subcat_background_color: [""]
      });
    }
  }
  subcategoryListAll() {
    this.digitalDisplayService
      .SubCategoriesOtionListMaster(this.storeId)
      .subscribe((data) => {
        this.subcategory_list_data = data.data;
      });
  }
  ModifierGroupListAll() {
    this.digitalDisplayService
      .ModifierGroupOtionListMaster(this.storeId)
      .subscribe((data) => {
        this.showModifierGroupSelete = data.data;
      });
  }
  addMasterCategoryFormSubmit() {
    if (this.addMasterCategoryForm.status == "VALID") {
      const keyData = {
        name: this.addMasterCategoryForm.value.name,
        new_screen: this.screen,
        priority: this.addMasterCategoryForm.value.priority,
        hide_category: this.hideCategory,
        store: this.storeId,
        subcategory: [],
      };
      this.isLoading = true;
      this.digitalDisplayService.addCategory(keyData).subscribe((data: any) => {
        if (data) {
          this.isLoading = false;
          this.masterCategory(data.id);
        }
      });
    }
  }

  addMasterCategoryAllStoreFormSubmit() {
    console.log('==================================', this.addMasterCategoryAllStoreForm.value);
    if (this.addMasterCategoryAllStoreForm.status == "VALID") {
      const keyData = {
        store: this.storeId,
        name: this.addMasterCategoryAllStoreForm.value.name,
        new_screen: this.screen,
        priority: this.addMasterCategoryAllStoreForm.value.priority,
        hide_mastercategory: this.hideCategory,
        // store: this.storeId,
        subcategorys: this.addMasterCategoryAllStoreForm.value.subcategory,
        modifiergroups: this.addMasterCategoryAllStoreForm.value.modifiergroups,
        font_color: this.addMasterCategoryAllStoreForm.value.font_color,
        emoji: this.addMasterCategoryAllStoreForm.value.emoji,
        alignment: this.addMasterCategoryAllStoreForm.value.alignment ? this.addMasterCategoryAllStoreForm.value.alignment : 'left',
        font_size: this.addMasterCategoryAllStoreForm.value.font_size ? this.addMasterCategoryAllStoreForm.value.font_size : 35,
        modifiergroups_subcat_alignment: this.addMasterCategoryAllStoreForm.value.modifiergroups_subcat_alignment ? this.addMasterCategoryAllStoreForm.value.modifiergroups_subcat_alignment : 'left',
        single_pricing_alignment: this.addMasterCategoryAllStoreForm.value.single_pricing_alignment ? this.
          addMasterCategoryAllStoreForm.value.single_pricing_alignment : 'right',
        price_format: this.addMasterCategoryAllStoreForm.value.price_format ? this.
          addMasterCategoryAllStoreForm.value.price_format : 'top_view',
        modifiergroups_subcat_font_size: this.addMasterCategoryAllStoreForm.value.modifiergroups_subcat_font_size ? this.addMasterCategoryAllStoreForm.value.modifiergroups_subcat_font_size : 35,

        modifiergroups_subcat_font_color: this.addMasterCategoryAllStoreForm.value.modifiergroups_subcat_font_color ? this.addMasterCategoryAllStoreForm.value.modifiergroups_subcat_font_color : '#ffffff',

        modifiergroups_subcat_background_color: this.addMasterCategoryAllStoreForm.value.modifiergroups_subcat_background_color ? this.addMasterCategoryAllStoreForm.value.modifiergroups_subcat_background_color : '#000000',

      };
      console.log('key data', keyData);
      this.isLoading = true;
      this.digitalDisplayService.addMasterCategory(keyData).subscribe((data: any) => {
        this.isLoading = false;
        this.dialogRef.close(data);
      });
    }
  }

  masterCategory(e) {
    const keyData = {
      name: this.addMasterCategoryForm.value.name,
      new_screen: this.screen,
      priority: this.addMasterCategoryForm.value.priority,
      hide_category: this.hideCategory,
      store: this.storeId,
      category: [e],
    };
    this.digitalDisplayService
      .addMasterCategory(keyData)
      .subscribe((data: any) => {
        this.dialogRef.close(data);
      });
  }

  newScreenCheck(a) {
    this.screen = a.checked;
  }
  hideCategoryCheck(a) {
    this.hideCategory = a.checked;
  }

  hideProductWeightCheck(a) {
    this.hideProductWeight = a.checked;
  }

  emojiList() {
    this.digitalDisplayService.getEmojiList().subscribe((data: any) => {
      this.emoji = data.data;

    });
  }

}
