import {Injectable, OnDestroy} from '@angular/core';
import {IPublishOptions, MqttService} from 'ngx-mqtt';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

@Injectable()
export class FrontFacingMqttService implements OnDestroy{
  paymentModeSubject = new BehaviorSubject<any>({});
  paymentMode = this.paymentModeSubject.asObservable();
  private tipDataSubject = new BehaviorSubject<any>({});
  tipData = this.tipDataSubject.asObservable();
  paymentModeSubscription: Subscription;
  private destroySubject: Subject<void> = new Subject();

  private decoder;
  constructor(private mq: MqttService) {  }

  ngOnDestroy() {
    this.destroySubject.next();
  }

  setCurrentOrder(data) {
    const qos = 2;
    const topicName = 'currentOrder/' + localStorage.getItem('selectedTerminal');
    console.log(topicName);
    this.mq.unsafePublish(topicName, JSON.stringify(data), { qos } as IPublishOptions);
  }

  getPaymentMode() {
    this.mq.connect()
    console.log('starting obs')
    const topicName = 'paymentMode/' + localStorage.getItem('selectedTerminal');
      this.paymentModeSubscription =    this.mq.observe(topicName).pipe(takeUntil(this.destroySubject)).subscribe(data => {
        console.log(topicName);
        const parsedData = this.decodeAndParse(data.payload);
        this.paymentModeSubject.next(parsedData);
      });
  }

  resetPaymentMode(){
    if (this.paymentMode){
      this.paymentModeSubject.next({});
    }
  }

  destroyPaymentModeSubscription(){
    if (this.paymentModeSubscription) {
      this.paymentModeSubscription.unsubscribe();
    }
  }

  setPaymentMode(paymentData){
    const qos = 2;
    const paymentModeTopicName = 'paymentModeSub/'+localStorage.getItem('selectedTerminal')
    console.log('published', paymentData)
    this.mq.unsafePublish(paymentModeTopicName,JSON.stringify(paymentData),{qos} as IPublishOptions)
   }

  decodeAndParse(payload) {
    this.decoder = new TextDecoder('utf-8');
    return JSON.parse(this.decoder.decode(payload));
  }

  getTipData() {
    const topicName = 'tippub/' + localStorage.getItem('selectedTerminal');
      this.mq.observe(topicName).pipe(takeUntil(this.destroySubject)).subscribe(data => {
        this.tipDataSubject.next(this.decodeAndParse(data.payload));
      });
  }

  publishTip(tipData) {
    console.log('publishing tip data');
    const qos = 2;
    const tipTopicName = 'tippub/' + localStorage.getItem('selectedTerminal');
      this.mq.unsafePublish(tipTopicName, JSON.stringify(tipData), { qos } as IPublishOptions);
  }

  resetTip (){
    this.tipDataSubject.next({})
  }

  goBackMqttPub() {
    const qos = 2;
    const payload = { goBack: true };
    const topicName = `goback/${localStorage.getItem('selectedTerminal')}/pub`;
      this.mq.unsafePublish(topicName, JSON.stringify(payload), { qos } as IPublishOptions);
  }

  goBackMqttSub() {
    const topicName = `goback/${localStorage.getItem('selectedTerminal')}/sub`;
      return this.mq.observe(topicName);
  }

  getPaymentStart() {
    const topicName = 'paymentStart/' + localStorage.getItem('selectedTerminal');
      return this.mq.observe(topicName);
  }

  publishStartPayment() {
    const payload = { onpay: true };
    const qos = 2;
    const topicName = 'paymentStart/' + localStorage.getItem('selectedTerminal');
      this.mq.unsafePublish(topicName, JSON.stringify(payload), { qos } as IPublishOptions);
  }

  getPaymentCancel() {
    const topicName = 'paymentCancel/' + localStorage.getItem('selectedTerminal');
      return this.mq.observe(topicName);
  }

  publishPaymentCancel() {
    const payload = { oncancel: true };
    const qos = 2;
    const topicName = 'paymentCancel/' + localStorage.getItem('selectedTerminal');
      this.mq.unsafePublish(topicName, JSON.stringify(payload), { qos } as IPublishOptions);
  }

  publishPaymentDone() {
    const payload = { type: 'paymentCompletedDone' };
    const qos = 2;
    const topicName = 'paymentDone/' + localStorage.getItem('selectedTerminal');
      this.mq.unsafePublish(topicName, JSON.stringify(payload), { qos } as IPublishOptions);
  }

  publishPaymentFailed() {
    const payload = { type: 'paymentFailed' };
    const qos = 2;
    const topicName = 'paymentDone/' + localStorage.getItem('selectedTerminal');
    this.mq.unsafePublish(topicName, JSON.stringify(payload), { qos } as IPublishOptions);
  }

  publishPaymentInProgress() {
    const payload = { inprogress: true, type: 'cash' };
    console.log('publishing payment in progress', payload);
    const qos = 2;
    const topicName = 'paymentInProgress/' + localStorage.getItem('selectedTerminal');
    this.mq.unsafePublish(topicName, JSON.stringify(payload), { qos } as IPublishOptions);
  }

  getRetryPayment() {
    const topicName = 'retryPayment/' + localStorage.getItem('selectedTerminal');
      return this.mq.observe(topicName);
  }

  publishSignatureStart(msg) {
    const payload = { startSignature: true, msg: msg };
    const qos = 2;
    const topicName = 'signatureStart/' + localStorage.getItem('selectedTerminal');
      this.mq.unsafePublish(topicName, JSON.stringify(payload), { qos } as IPublishOptions);
  }

  getPaymentDoneStatus() {
    const topicName = 'paymentDone/' + localStorage.getItem('selectedTerminal');
      return this.mq.observe(topicName);
  }

  getSignatureDoneStatus() {
    const topicName = 'signatureDone/' + localStorage.getItem('selectedTerminal');
      return this.mq.observe(topicName);
  }

  refreshOpenOrder (){
    const qos = 2
    const payload = { refreshOpenOrder: true,  };
    const topicName = 'refreshOpenOrder/' + localStorage.getItem('selectedTerminal');
    this.mq.unsafePublish(topicName, JSON.stringify(payload), { qos } as IPublishOptions);
  }



  publishToFrontFacing(payload: any){
    const qos = 2;
    const tipTopicName = 'fromff/' + localStorage.getItem('selectedTerminal');
    console.log(tipTopicName, payload);
    this.mq.unsafePublish(tipTopicName,JSON.stringify(payload),{qos} as IPublishOptions)
  }

  listenFromFrontFacing(){
    const topicName = 'toff/' + localStorage.getItem('selectedTerminal');
    return this.mq.observe(topicName).pipe(map((x) => this.decodeAndParse(x.payload)))
  }

  listenForDeviceConnections(){
    const topic = `device/${localStorage.getItem('selectedTerminal')}`
    console.log(topic);
    return this.mq.observe(topic).pipe(map((x) => this.decodeAndParse(x.payload)))
  }

  listenForDeviceDisconnection(){
    const topic = `device/disconnect/${localStorage.getItem('selectedTerminal')}`
    console.log(topic);
    return this.mq.observe(topic).pipe(map((x) => this.decodeAndParse(x.payload)))
  }

  sendHello(){
    const payload = {hello: true}
    this.publishToFrontFacing(payload);
  }
}
