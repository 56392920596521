import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {StoreService} from 'src/app/_services/store.service';
import {Store} from 'src/app/_models/store';
import {UnreadService} from 'src/app/_services/unread-message.service';

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss']
})
export class ContactListComponent implements OnInit, OnChanges {

  isLoading = false;
  store:Store;
  contactList:Object = {};
  contactOrder:string[];
  @Output() userSelected = new EventEmitter<any>();
  @Input() toDelete;
  selectedUser;
  unreadCounter = {}
  @ViewChild('searchBox',{static: false}) searchBox: ElementRef<HTMLInputElement>;
  opened:boolean=true;
  @Input() sidebar


  constructor(private storeService:StoreService,
    private unreadService:UnreadService) { }

  ngOnInit() {
    this.storeService.current.subscribe(store => {
      if(store){
        this.store = store;
        this.getContactList("");
        this.unreadService.current.subscribe(counter => {
          if(counter){
            this.unreadCounter = counter
          }
        })
      }
    })
  }

  ngOnChanges(changes:SimpleChanges):void{
    console.log(changes);
    if(changes.toDelete){
      let toDeleteUser = changes.toDelete.currentValue;
      delete this.contactList[toDeleteUser.phone_number];
    }
  }

  getPlainPhoneNumberFromString(phoneNumber:string){
    let ph = phoneNumber.replace(/[\s()-]/g, '')
    return '+1' + ph.slice(ph.length - 10)
  }

  getContactList(filter:string){
    this.storeService.getChatList(this.store.id, filter).subscribe(data => {
      this.contactList = data.groups;
      this.contactOrder = data.order;
      const phoneNumber = this.getPlainPhoneNumberFromString(filter);
      if(phoneNumber.length === 12  && !(phoneNumber in this.contactList)){
        const nonGuest  = {'unread_count': 0, 'phone_number': phoneNumber, 'type': 'new'}
        this.contactList[phoneNumber] = nonGuest
      }
      this.onUserSelected(this.contactOrder[0]);
    })
  }

  onUserSelected(user){
    this.selectedUser = this.contactList[user];
    this.userSelected.emit(this.selectedUser)
  }

  updateUnreadCount(user, count){
    this.contactList[user].unread_count = count
  }

  markAsRead(user){
    this.contactList[user].unread_count = 0;
  }

  newContact(){
    this.searchBox.nativeElement.focus()
  }
}
