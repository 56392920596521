import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Subject} from 'rxjs';
import {OpenOrderToggleService} from 'src/app/_services/open-order-toggle.service';
import {POSService} from 'src/app/_services/pos-service';
import {RefreshService} from 'src/app/_services/refresh.service';
import {OrderService} from '../../_services/order.service';
import { Store } from 'src/app/_models/store';
import {FrontFacingMqttService} from 'src/app/_services/front-facing-mqtt.service';
import {POSSummaryToggleService} from 'src/app/_services/pos-summary-toggle.service';
import {POSMenuTabChangeService} from 'src/app/_services/pos-menu-tab-change.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import { FloorPlanService } from 'src/app/_services/floor-plan.service';


@Component({
  selector: 'app-cash-payment',
  templateUrl: './cash-payment.component.html',
  styleUrls: ['./cash-payment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CashPaymentComponent implements OnInit, OnDestroy {
  public calculatedTotal = this.data.result - this.data.cashDueTotal;
  public absTotal = Math.abs(this.calculatedTotal);
  public showContent = false;
  public unsubscribe: Subject<null> = new Subject<null>();
  result;
  isLoading = true;
  terminalId;
  posLoggedUser;
  terminalName;
  pinUser: string;
  totalDueAmount:any;
  store: Store;
  manualData:any;
  constructor(public dialogRef: MatDialogRef<CashPaymentComponent>,
              public orderService: OrderService,
              private posService: POSService,
              private refreshService: RefreshService,
              private openOrderSummaryToggleService: OpenOrderToggleService,
              public dialog: MatDialog,
              private mq: FrontFacingMqttService,
              private posSummaryToggleService: POSSummaryToggleService,
              private  tabChangeService: POSMenuTabChangeService ,
              private floorPlanService: FloorPlanService,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {

    const orderHash = localStorage.getItem('order_hash');
    const orderWithPayment = this.data.orderWithPayment;
    this.terminalId = localStorage.getItem('selectedTerminal');
    this.posLoggedUser = localStorage.getItem('posLoggedUser');
    this.terminalName = localStorage.getItem('selectedTerminalName');
    if(this.data.split_type == 'GIFT' || this.data.split_type == 'KEYED'){
     this.totalDueAmount = Number(this.data.totalDueAmount) - Number(this.data.cashTotal);
    }
    this.pinUser = localStorage.getItem('pinUser');
    const payload = {
      cash_total: this.data.cashDueTotal,
      terminal_id: this.terminalName,
      terminal_name: this.terminalName,
      cashier_id: this.posLoggedUser,
      change_due: this.absTotal,
      payment_type: this.data.split_type,
      split_id: this.data.split_id,
      split_amount: this.data.cashDueTotal,
      pin_user: this.pinUser
    };

    if (this.data.is_split) {
      this.orderService.processSplit(orderWithPayment.bot_order.order_hash, payload).pipe(takeUntil(this.unsubscribe)).subscribe(result => {
        this.result = result;
        this.isLoading = false;
        this.openDrawer();

      });
    } else if (this.data.is_other) {
      this.orderService.isCashCLose.next(false);
      this.orderService.closeSplitPayment(orderWithPayment.bot_order.order_hash, payload).pipe(takeUntil(this.unsubscribe)).subscribe(result => {
        this.result = result;
        this.isLoading = false;
        this.orderService.isCashCLose.next(true);
        this.openDrawer();

      });
    } else {
      this.orderService.isCashCLose.next(false);
      this.orderService.closeCashOrder(orderWithPayment.bot_order.order_hash, payload).pipe(takeUntil(this.unsubscribe))
      .subscribe((result) => {
        this.result = result;
        this.isLoading = false;
        this.orderService.isCashCLose.next(true);
        localStorage.removeItem('order_hash');
        this.openOrderSummaryToggleService.toggle('CLOSE');
        this.openDrawer();
      });
    }

    this.store = this.data.orderWithPayment.store;
    
    this.manualData = {
      value: 1,
      order_hash:this.data.orderWithPayment.bot_order.order_hash,
      payment_cash: (this.data.is_split || this.data.is_other) ? false : true
    }
    setTimeout(()=>this.showContent=true, 1000);
  }

  doneClick() {
    if (!this.data.is_split) {
      localStorage.removeItem('setOccasionDetail');
      const orderWithPayment = this.data.orderWithPayment;
      orderWithPayment.bot_order.is_closed = true;
      this.dialogRef.close(true);
      this.orderService.orderDoneSubject.next();
      this.refreshService.refreshModule('OPEN_TAB');
      this.mq.publishPaymentDone();
      this.posSummaryToggleService.toggle('CLOSE');
      this.tabChangeService.changeTab(0);
      this.orderService.keyboardValueSet.next(false);
      this.floorPlanService.floorCreatedUpdate.next(false);
      
    } else {
      this.dialogRef.close(false);
    }

  }

  openDrawer() {
    const terminal_id = localStorage.getItem('selectedTerminal');
    this.posService.openCashDrawer(terminal_id).pipe(takeUntil(this.unsubscribe)).subscribe(
      (data) => {
        console.log('drawer opened');
        this.orderService.errorMessage('Cash drawer opened successfully', 'OK', 1000);
      },
      (error) => {
        this.orderService.errorMessage('Unable to open cash drawer', 'OK', 2000);
      }
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
