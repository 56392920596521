<div class="example-container">
  <form [formGroup]="taxes" class="p-6	" (ngSubmit)="editEmployeeTax()">
    <div>
      <mat-form-field appearance="fill" style="width: 100%" class="form-fieldd">
        <mat-label>State Tax </mat-label>
        <input step="0.1" type="number" matInput placeholder="" formControlName="state_tax_percent">
      </mat-form-field>

      <mat-form-field appearance="fill" style="width: 100%" class="form-fieldd">
        <mat-label>Federal Tax</mat-label>
        <input step="0.1" type="number" matInput placeholder="" formControlName="federal_tax_percent">
      </mat-form-field>

    </div>
    <button mat-raised-button type="submit">Save</button>
  </form>
</div>