import { Component, OnInit } from '@angular/core';
import { ReferralSettingsService } from 'src/app/_services/referral-settings.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
// referral-settings.service
@Component({
  selector: 'app-referral-settings',
  templateUrl: './referral-settings.component.html',
  styleUrls: ['./referral-settings.component.scss']
})
export class ReferralSettingsComponent implements OnInit {

  storeId: any;
  public myForm: FormGroup;
  pointsList: any;
  isChecked = true;
  showPin: any;
  custom_default_message: any;
  slugName: any;
  constructor(public ReferralSettingsService: ReferralSettingsService, public snackBar: MatSnackBar, private formBuilder: FormBuilder) {
    this.myForm = formBuilder.group({
      mob: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{6}$")]]
    })
  }

  ngOnInit() {

    this.storeId = localStorage.getItem('currentStore');
    this.slugName = localStorage.getItem('slug');
    this.getReferralPromotionlistList();
    this.getPin();
  }

  getReferralPromotionlistList() {
    this.ReferralSettingsService.getReferralPromotionlist(this.storeId).subscribe((data: any) => {
      this.pointsList = data.data;
    })
  }

  get m() {
    return this.myForm.controls;
  }

  updatePromotionValue(p, cv, c, campaignNname) {
    const sendData = {
      "id": c.id,
      "referee_gets_dollar": p,
      "referrer_gets_dollar": cv,
      "campaign_name": campaignNname
    }
    this.ReferralSettingsService.updateReferralPromotionValue(sendData).subscribe((data: any) => {
      if (data.is_verified == 'false') {
        this.snackBar.open('Campaign name already exists', 'OK', { duration: 2000 });
      }
      else {
        this.snackBar.open('Item has been updated', 'OK', { duration: 2000 });
        this.getReferralPromotionlistList();
      }
    })
  }

  noReferralPromotion() {
    const sendData = {
      "referee_gets_dollar": 0,
      "referrer_gets_dollar": 0,
      "store_id": this.storeId
    }
    this.ReferralSettingsService.createReferralPromotion(sendData).subscribe((data: any) => {
      this.snackBar.open('Item has been updated', 'OK', { duration: 2000 });
      this.getReferralPromotionlistList();
    })
  }


  addReferralPromotionRow() {
    const sendData = {
      "id": '',
      "referee_gets_dollar": 10,
      "referrer_gets_dollar": 10,
      "store_id": this.storeId
    }
    this.ReferralSettingsService.createReferralPromotion(sendData).subscribe((data: any) => {
      this.getReferralPromotionlistList();
    })
  }

  deleteReferralPromotionRow(row) {
    const sendData = {
      "id": row.id,
      "referee_gets_dollar": row.referee_gets_dollar,
      "referrer_gets_dollar": row.referrer_gets_dollar,
      "store_id": row.store,
      "is_deleted": true
    }
    this.ReferralSettingsService.deletedReferralPromotion(sendData).subscribe((data: any) => {
      this.getReferralPromotionlistList();
    })
  }
  getPin() {
    this.ReferralSettingsService.getPin(this.storeId).subscribe((data: any) => {
      console.log('sadasd', data.data);

      if (data.data.length != 0) {
        this.showPin = data.data[0].pin;
        this.custom_default_message = data.data[0].custom_default_message
      }
      else {
        this.showPin = '';
      }
    })
  }
  updatePin() {
    const sendData = {
      "store_id": this.storeId,
      "pin": this.showPin,
    }
    this.ReferralSettingsService.enterPin(sendData).subscribe((data: any) => {
      this.snackBar.open('Item has been updated', 'OK', { duration: 2000 });
      this.getPin();
    })
  }
  updateCustom() {
    const sendData = {
      "store_id": this.storeId,
      "custom_default_message": this.custom_default_message,
    }
    this.ReferralSettingsService.enterPin(sendData).subscribe((data: any) => {
      this.snackBar.open('Item has been updated', 'OK', { duration: 2000 });
      this.getPin();
    })
  }

  disabledReferralPromotion(row) {
    const sendData = {
      "id": row.id,
      "is_enabled": row.is_enabled
    }
    this.ReferralSettingsService.disabledReferralPromotion(sendData).subscribe((data: any) => {
    })
  }

  copyData(data) {
    var inputc = document.body.appendChild(document.createElement("input"));
    inputc.value = environment.orderAppUrl + '/store/' + this.slugName + '/referral-promotion/' + data.id;
    inputc.focus();
    inputc.select();
    document.execCommand('copy');
    inputc.parentNode?.removeChild(inputc);
    this.snackBar.open('Copied', 'OK',);

  }

}
