import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/_services/user.service';
import { DatePipe } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'app-add-time-clock',
  templateUrl: './add-time-clock.component.html',
  styleUrls: ['./add-time-clock.component.scss']
})
export class AddTimeClockComponent implements OnInit {
  addTimeFrom: UntypedFormGroup;
  clockedDateTime: any;
  clockedOutDateTime: Date = new Date();
  clockedDateTime1: Date = new Date();
  clockedOutDateTime1: any;
  startTime1: any;
  endTime1: any;
  startTime: Date = new Date();
  endTime: Date = new Date();
  timeStart: boolean = false;
  store_id: any;
  userNames: any;
  constructor(
    public fb: UntypedFormBuilder,
    public userService: UserService,
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<AddTimeClockComponent>,
  ) { }

  ngOnInit() {
    this.addTimeFrom = this.fb.group({
      user_name: ["", Validators.required],
      clocked_in: ["", Validators.required],
      clocked_out: ["", Validators.required],
      start_break: [{ value: "", disabled: true }],
      end_break: [{ value: "", disabled: true }],
      number_of_breaks: [null],
    });

    this.store_id = localStorage.getItem("currentStore")
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.userService.getAllUser(this.store_id, user["username"]).subscribe((data: any) => {
      this.userNames = data;
    });

    this.addTimeFrom.get('number_of_breaks').valueChanges.pipe(
      startWith('') // Emit initial value as blank string
    ).subscribe((value) => {
      if (parseInt(value, 10) > 0) { // Convert value to integer for comparison
        this.addTimeFrom.get('start_break').setValidators([Validators.required]);
        this.addTimeFrom.get('end_break').setValidators([Validators.required]);
        this.addTimeFrom.get('start_break').enable();
      } else {
        const startBreakVal = this.addTimeFrom.get('start_break');
        const endBreakVal = this.addTimeFrom.get('end_break');
        const numberBreakkVal = this.addTimeFrom.get('number_of_breaks');
        if (startBreakVal.validator) {
          this.addTimeFrom.get('start_break').clearValidators();
          this.addTimeFrom.get('start_break').disable();
        }
        if (endBreakVal.validator) {
          this.addTimeFrom.get('end_break').clearValidators();
        }
        if (numberBreakkVal.validator) {
          this.addTimeFrom.get('end_break').clearValidators();
        }
      }
      this.addTimeFrom.get('end_break').updateValueAndValidity();
      this.addTimeFrom.get('start_break').updateValueAndValidity();
    });

    this.addTimeFrom.get('start_break').valueChanges.pipe(
      startWith('')
    ).subscribe((value) => {
      if (value) {
        this.addTimeFrom.get('end_break').enable();
      } else {
        this.addTimeFrom.get('end_break').disable();

      }
    });


    // this.addTimeFrom.get('start_break').valueChanges.subscribe((value) => {
    //   console.log('@', value);
      
    //   if (value) {
    //     const startBreakVal = this.addTimeFrom.get('start_break');
    //     const endBreakVal = this.addTimeFrom.get('end_break');
    //     const numberBreakkVal = this.addTimeFrom.get('number_of_breaks');
    //     if (numberBreakkVal.value && !numberBreakkVal.validator) {
    //       numberBreakkVal.setValidators([Validators.required]);
    //     }
    //     if (endBreakVal.value && !endBreakVal.validator) {
    //       endBreakVal.setValidators([Validators.required]);
    //     }
    //     this.addTimeFrom.get('end_break').updateValueAndValidity();
    //     this.addTimeFrom.get('number_of_breaks').updateValueAndValidity();
    //   }
    // });

    // this.addTimeFrom.get('end_break').valueChanges.subscribe((value) => {
    //   console.log('###', value);

    //   if (value) {
    //     const startBreakVal = this.addTimeFrom.get('start_break');
    //     const endBreakVal = this.addTimeFrom.get('end_break');
    //     const numberBreakkVal = this.addTimeFrom.get('number_of_breaks');
    //     if (numberBreakkVal.value && !numberBreakkVal.validator) {
    //       numberBreakkVal.setValidators([Validators.required]);
    //     }
    //     if (startBreakVal.value && !startBreakVal.validator) {
    //       startBreakVal.setValidators([Validators.required]);
    //     }
    //     this.addTimeFrom.get('start_break').updateValueAndValidity();
    //     this.addTimeFrom.get('number_of_breaks').updateValueAndValidity();
    //   }
    // });
    
    // this.addTimeFrom.get('start_break').valueChanges.subscribe((value) => {
    //   console.log('dtart break');
      
    //   if (value) {
    //     this.addTimeFrom.get('end_break').setValidators([Validators.required]);
    //     this.addTimeFrom.get('number_of_breaks').setValidators([Validators.required]);
    //     this.timeStart = true;
    //   } else {
    //     this.addTimeFrom.get('end_break').clearValidators();
    //     this.addTimeFrom.get('number_of_breaks').clearValidators();
    //   }
    //   this.addTimeFrom.get('end_break').updateValueAndValidity();
    //   this.addTimeFrom.get('number_of_breaks').updateValueAndValidity();
    // });

  }

  addTimeFromSubmit() {
    const formData = {
      "user": this.addTimeFrom.controls.user_name.value.id,
      "clock_in_time": this.datePipe.transform(this.addTimeFrom.controls.clocked_in.value, 'yyyy-MM-dd HH:mm:00'),
      "clock_out_time": this.datePipe.transform(this.addTimeFrom.controls.clocked_out.value, 'yyyy-MM-dd HH:mm:00'),
      "start_break_time": this.addTimeFrom.controls.start_break.value ? this.datePipe.transform(this.addTimeFrom.controls.start_break.value, 'yyyy-MM-dd HH:mm:00') : null,
      "end_break_time": this.addTimeFrom.controls.end_break.value ? this.datePipe.transform(this.addTimeFrom.controls.end_break.value, 'yyyy-MM-dd HH:mm:00') : null,
      "number_of_breaks": this.addTimeFrom.controls.number_of_breaks.value ? this.addTimeFrom.controls.number_of_breaks.value : 0
    }

    this.userService.addUserTimeClock(this.store_id, formData).subscribe((data: any) => {
      console.log('API Run Successfully', data);
      this.dialogRef.close();

    });
  }

}