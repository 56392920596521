import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { StoreService } from 'src/app/_services/store.service';
import { Store } from 'src/app/_models/store';
import { FormUtils } from 'src/app/_helpers/form-utils';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PermissionService } from 'src/app/_services/permission.service';
import { Permission } from 'src/app/_models/permission';
import { OrderService } from 'src/app/_services/order.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SelectSummaryToprintComponent } from "../select-summary-toprint/select-summary-toprint.component";


@Component({
  selector: "app-mini-report",
  templateUrl: "./mini-report.component.html",
  styleUrls: ["./mini-report.component.scss"],
})
export class MiniReportComponent implements OnInit {
  data = [
    {
      id: 1,
      name: "Abc",
      email: "abc@mail.com",
      isExpand: false,
      address: [
        {
          add1: "Delhi",
          add2: "Bangalore",
        },
      ],
    },
    {
      id: 2,
      name: "Xyz",
      email: "xyz@mail.com",
      isExpand: false,
      address: [
        {
          add1: "Mumbai",
          add2: "Pune",
        },
      ],
    },
    {
      id: 3,
      name: "ijk",
      email: "ijk@mail.com",
      isExpand: false,
      address: [
        {
          add1: "Chennai",
          add2: "Bangalore",
        },
      ],
    },
    {
      id: 4,
      name: "def",
      email: "def@mail.com",
      isExpand: false,
      address: [
        {
          add1: "Kolkata",
          add2: "Hyderabad",
        },
      ],
    },
  ];

  startDate;
  endDate;
  store: Store;
  report;
  hours = this.formUtils.buildTimeOptions();
  from;
  to;
  form;
  permission: Permission;
  permissionPIN: Permission;
  empReport;
  totalEmpReport: any;
  currentPIN = localStorage.getItem("currentPIN");
  pullAll = false;

  now = moment().local().toDate();

  isLoading = false;
  categoryReport: any;
  public unsubscribe: Subject<null> = new Subject<null>();
  terminalList = [];
  posHaveorNot: boolean = true;
  store_id: any;
  formAvailable: boolean = false;
  stationName: any;
  selectedTerminalName: string  = localStorage.getItem('selectedTerminalName');
  printers: any[];
  foodCostReport: any;
  profitabilityReport:any;
  smryPrintChoice: any
  cashDrawerReport: any;
  constructor(
    private storeService: StoreService,
    private _snackBar: MatSnackBar,
    private formUtils: FormUtils,
    private permissionService: PermissionService,
    private orderService: OrderService,
    private dialog: MatDialog
  ) {

  }

  ngOnInit() {
    this.store_id = localStorage.getItem('currentStore');
    this.storeService.getStorePrinters(this.store_id).pipe(takeUntil(this.unsubscribe)).subscribe((response) => {
      this.printers = response;
    });
    this.orderService.getTerminalId(this.store_id).pipe(takeUntil(this.unsubscribe)).subscribe((response) => {
      if (response.length > 0) {
        this.terminalList.push({ name: 'All' });
        response.map(res => {
          this.terminalList.push({ name: res.name });
        });
        this.form = this.getForm();
        this.formAvailable = true;
      } else {
        this.formAvailable = true;
      }
    });
    this.permissionService.pinCurrentOperator.subscribe((permission) => {
      this.permission = permission;
      this.permissionPIN = permission;
      this.form = this.getForm();
      this.pullAll = false;
    });

    this.storeService.current.subscribe((store) => {
      if (store) {
        this.store = Object.assign(new Store(), store);
        if (!this.store.hasModule("POS")) {
          this.posHaveorNot = false;
          this.permissionService.current.subscribe((permission) => {
            this.permission = permission;
            this.permissionPIN = permission;
            this.form = this.getForm();
            this.pullAll = false;
          });
        }
        this.startDate = moment().local().toDate();
        this.endDate = moment().local().toDate();
        setTimeout(() => {
          this.searchOrders();
        }, 1000);
      }
    });

    // this.permissionService.pinCurrentOperator.subscribe((permission) => {
    //   this.permissionPIN = permission;
    // });

    // this.covenants.forEach((_covenants) => {
    //   _covenants.isExpanded = false;
    // });
  }

  getForm() {
    return new UntypedFormGroup({
      start_date: new UntypedFormControl({
        value: moment().local().toDate(),
        disabled: !this.hasReportingSearch(),
      }),
      end_date: new UntypedFormControl({
        value: moment().local().toDate(),
        disabled: !this.hasReportingSearch(),
      }),
      from: new UntypedFormControl(this.hours[0]),
      to: new UntypedFormControl(this.hours[this.hours.length - 1]),
      station: new UntypedFormControl(this.terminalList[0]),
    });
  }

  hasReportingSearch() {
    if (this.permission != null) {
      return this.permission.hasPermission("REPORTING", "SEARCH");
    }
  }

  hourCompare(left, right) {
    return left.value === right.value;
  }

  searchOrders() {
    this.isLoading = true;
    this.pullAll = false;

    if (this.form.value.station == null) {
      this.stationName = ''
    } else {
      this.stationName = this.form.value.station.name;
    }
    /* Sales SUmmary */
    this.storeService
      .getSalesMiniReport(
        this.store.id,
        this.form.value.start_date || this.now,
        this.form.value.end_date || this.now,
        this.form.value.from,
        this.form.value.to,
        this.stationName,
        localStorage.getItem("currentPIN")
      )
      .subscribe((data: any) => {
        this.report = data;
        this.isLoading = false;

        /* Category Summary */
        this.storeService
          .getCategoryMiniReport(
            this.store.id,
            this.form.value.start_date || this.now,
            this.form.value.end_date || this.now,
            this.form.value.from,
            this.form.value.to,
            this.stationName,
            localStorage.getItem("currentPIN")
          )
          .subscribe((data: any) => {
            this.categoryReport = data;
            this.isLoading = false;

            /* Labor Summary */
            this.storeService
              .getStoreEmpReport(
                this.store.id,
                this.form.value.start_date || this.now,
                this.form.value.end_date || this.now,
                this.form.value.from,
                this.form.value.to,
                this.stationName,
                localStorage.getItem("currentPIN")
              )
              .subscribe((data: any) => {
                this.totalEmpReport = data.total_records;
                this.empReport = data.all_records;
                this.isLoading = false;

                /* Food Cost Summary */
                this.storeService.getFoodCostReport(
                  this.store.id,
                  this.form.value.start_date || this.now,
                  this.form.value.end_date || this.now,
                  this.form.value.from,
                  this.form.value.to,
                  this.stationName,
                  localStorage.getItem("currentPIN")
                )
                .subscribe((data:any) => {
                  this.foodCostReport = data;

                  /* Profitability Report */
                  this.storeService.getProfitabilityReport(
                    this.store.id,
                    this.form.value.start_date || this.now,
                    this.form.value.end_date || this.now,
                    this.form.value.from,
                    this.form.value.to,
                    this.stationName,
                    localStorage.getItem("currentPIN")
                  )
                  .subscribe((data:any) => {
                    this.profitabilityReport = data;

                    if (this.store.feature_flag['cash_drawer_management']) {
                      /* Cash Drawer Report */
                      this.storeService.getCashDrawerReport(
                        this.store.id,
                        this.form.value.start_date || this.now,
                        this.form.value.end_date || this.now,
                        this.form.value.from,
                        this.form.value.to,
                        this.stationName,
                        localStorage.getItem("currentPIN")
                      )
                      .subscribe((data:any) => {
                        this.cashDrawerReport = data;
                        
                      });
                    }
                  });
                });
              });
          });
      });
  }

  searchOrdersAll() {
    this.isLoading = true;
    this.pullAll = true;
    if (this.form.value.station == null) {
      this.stationName = ''
    } else {
      this.stationName = this.form.value.station.name;
    }
    /* Sales Summary All */
    this.storeService
      .getSalesMiniReportAll(
        this.store.id,
        this.form.value.start_date || this.now,
        this.form.value.end_date || this.now,
        this.form.value.from,
        this.form.value.to,
        this.stationName,
      )
      .subscribe((data: any) => {
        this.report = data;
        this.isLoading = false;

        /* Category Summary All */
        this.storeService
          .getCategoryMiniReportAll(
            this.store.id,
            this.form.value.start_date || this.now,
            this.form.value.end_date || this.now,
            this.form.value.from,
            this.form.value.to,
            this.stationName,
          )
          .subscribe((data: any) => {
            this.categoryReport = data;
            this.isLoading = false;

            /* Labor Summary All */
            this.storeService
              .getStoreEmpReportAll(
                this.store.id,
                this.form.value.start_date || this.now,
                this.form.value.end_date || this.now,
                this.form.value.from,
                this.form.value.to,
                this.stationName,
              )
              .subscribe((data: any) => {
                this.totalEmpReport = data.total_records;
                this.empReport = data.all_records;
                this.isLoading = false;

                /* Food Cost Summary */
                this.storeService.getFoodCostReportAll(
                  this.store.id,
                  this.form.value.start_date || this.now,
                  this.form.value.end_date || this.now,
                  this.form.value.from,
                  this.form.value.to,
                  this.stationName,
                )
                .subscribe((data:any) => {
                  this.foodCostReport = data;

                  /* Profitability Report */
                  this.storeService.getProfitabilityReportAll(
                    this.store.id,
                    this.form.value.start_date || this.now,
                    this.form.value.end_date || this.now,
                    this.form.value.from,
                    this.form.value.to,
                    this.stationName,
                  )
                  .subscribe((data:any) => {
                    this.profitabilityReport = data;

                    if (this.store.feature_flag['cash_drawer_management']) {
                      /* Cash Drawer Report */
                      this.storeService.getCashDrawerReportAll(
                        this.store.id,
                        this.form.value.start_date || this.now,
                        this.form.value.end_date || this.now,
                        this.form.value.from,
                        this.form.value.to,
                        this.stationName,
                      )
                      .subscribe((data:any) => {
                        this.cashDrawerReport = data;
                      });
                    }
                  });
                });
              });
          });
      });
  }
  
  printAllSummaries(rprt, printer, result) {
    if (localStorage.getItem("currentPIN")) {
      this.print(rprt, printer, 1);
      this.print(rprt, printer, 2);
      this.print(rprt, printer, 3);
    } else {
      this.print(rprt, printer, 4);
    }
  }
  

  selectedSummaryPrint(rprt, printer) {
    let dialogRef = this.dialog.open(SelectSummaryToprintComponent, {
      width: '390px',
      height: '380px',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('After Closed', result);
      if (result) {
        if(result ==4){
          this.smryPrintChoice = result;
          this.printAllSummaries(rprt, printer,result)
        }else{
          this.smryPrintChoice = result;
          let printNo= result
          this.print(rprt, printer,printNo);
        }
      
      }
    });
  }

  print(report, printer,printNo) {
    console.log('rrrrrrrrrrr', report);
    
    if (this.form.value.station == null) {
      this.stationName = '';
    } else {
      this.stationName = this.form.value.station.name;
    }
    this.isLoading = true;
    const terminalName = localStorage.getItem('selectedTerminalName') ? localStorage.getItem('selectedTerminalName') : 'NA';
    if (!this.pullAll) {
      this.storeService
        .printStoreMiniReport(
          this.store.id,
          terminalName,
          this.form.value.start_date || this.now,
          this.form.value.end_date || this.now,
          this.form.value.from,
          this.form.value.to,
          this.stationName,
          localStorage.getItem("currentPIN"),
          report,
          printer ? printer.printer_id : null,
          printNo
        )
        .subscribe(
          (data) => {
            this.isLoading = false;
            this._snackBar.open("Report Printed Successfully!", "OK", {
              duration: 1000,
            });
          },
          (error) => {
            this._snackBar.open("Unable to print report", "OK");
            this.isLoading = false;
          }
        );
    } else {
      this.storeService
        .printStoreMiniReportAll(
          this.store.id,
          terminalName,
          this.form.value.start_date || this.now,
          this.form.value.end_date || this.now,
          this.form.value.from,
          this.form.value.to,
          this.stationName,
          report,
          printer ? printer.printer_id : null,
          printNo
        )
        .subscribe(
          (data) => {
            this.isLoading = false;
            this._snackBar.open("Report Printed Successfully!", "OK", {
              duration: 1000,
            });
          },
          (error) => {
            this._snackBar.open("Unable to print report", "OK");
            this.isLoading = false;
          }
        );
    }
  }

  reset() {
    this.form = this.getForm();
    this.searchOrders();
  }
}
