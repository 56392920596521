<mat-tab-group class="digital-display-pos">
  <!-- <mat-tab label="SCREENS">
    <div class="coloredContainerX pad-2" fxlayout="row" fxLayout.lt-sm="column" cols="2">
      <div fxFlex.xs="100%" fxFlex.lt-md="100%" fxFlex="100%">
        <div class="card">
          <app-d-d-screens></app-d-d-screens>
          <app-digital-screens></app-digital-screens>
        </div>
      </div>
    </div>
  </mat-tab> -->
  <mat-tab label="SCREENS">
    <ng-template matTabContent>

      <div class="coloredContainerX pad-2" fxlayout="row" fxLayout.lt-sm="column" cols="2">
        <div fxFlex.xs="100%" fxFlex.lt-md="90%" fxFlex="100%">
          <div class="card">
            <app-d-d-screens></app-d-d-screens>
            <!-- <app-digital-screens></app-digital-screens> -->
          </div>
        </div>
      </div>

    </ng-template>
  </mat-tab>
  <mat-tab label="TV MANAGEMENT">
    <ng-template matTabContent>
      <div class="coloredContainerX pad-2" fxlayout="row" fxLayout.lt-sm="column" cols="2">
        <div fxFlex.xs="100%" fxFlex.lt-md="90%" fxFlex="100%">
          <div class="card">
            <app-column-management></app-column-management>
          </div>
        </div>
      </div>

    </ng-template>
  </mat-tab>


  <mat-tab label="MENU CATEGORIES">
    <ng-template matTabContent>
      <div class="coloredContainerX pad-2" fxlayout="row" fxLayout.lt-sm="column" cols="2">
        <div fxFlex.xs="100%" fxFlex.lt-md="90%" fxFlex="100%">
          <div class="card">
            <app-d-d-categories></app-d-d-categories>
          </div>
        </div>
      </div>

    </ng-template>
  </mat-tab>


  <mat-tab label="SCREEN MEDIA">
    <div class="coloredContainerX pad-2" fxlayout="row" fxLayout.lt-sm="column" cols="2">
      <div fxFlex.xs="100%" fxFlex.lt-md="90%" fxFlex="100%">
        <div class="card">
          <app-screen-media></app-screen-media>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="SCREEN IMAGES" *ngIf="storeType != 'Leaflogix'">
    <div class="coloredContainerX pad-2" fxlayout="row" fxLayout.lt-sm="column" cols="2">
      <div fxFlex.xs="100%" fxFlex.lt-md="90%" fxFlex="100%">
        <div class="card">
          <app-restaurant-images></app-restaurant-images>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="SCREEN VIDEO">
    <div class="coloredContainerX pad-2" fxlayout="row" fxLayout.lt-sm="column" cols="2">
      <div fxFlex.xs="100%" fxFlex.lt-md="90%" fxFlex="100%">
        <div class="card">
          <app-screen-video></app-screen-video>
        </div>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="DEFAULT SCREEN">
    <ng-template matTabContent>
      <div class="coloredContainerX pad-2" fxlayout="row" fxLayout.lt-sm="column" cols="2">
        <div fxFlex.xs="100%" fxFlex.lt-md="90%" fxFlex="100%">
          <div class="card">
            <app-default-screen></app-default-screen>
          </div>
        </div>
      </div>

    </ng-template>
  </mat-tab>

  <mat-tab label="DEFAULT MENU CATEGORIES"  *ngIf="storeType != 'Leaflogix'">
    <ng-template matTabContent>
      <div class="coloredContainerX pad-2" fxlayout="row" fxLayout.lt-sm="column" cols="2">
        <div fxFlex.xs="100%" fxFlex.lt-md="90%" fxFlex="100%">
          <div class="card">
            <app-default-menu-categories></app-default-menu-categories>
          </div>
        </div>
      </div>

    </ng-template>
  </mat-tab>

</mat-tab-group>