import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {OrdersWithPayment} from 'src/app/_models/order';
import {OrderService} from 'src/app/_services/order.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';


@Component({
  selector: 'app-order-summary-tip',
  templateUrl: './tip.component.html',
  styleUrls: ['./tip.component.scss']
})
export class TipComponent implements OnInit, OnChanges, OnDestroy {

  @Input() orderWithPayment: OrdersWithPayment;
  @Input() orderHash: string;

  tipPercent = 33;

  @Output() refresh = new EventEmitter<any>();

    tips = [
    {name: '18%', value: 18},
    {name: '20%', value: 20},
    {name: '25%', value: 25},
    {name: 'Custom', value: 0},
  ];

  customTipHidden = true;
  private destroySubject: Subject<void> = new Subject();
  constructor(
    private orderService: OrderService,
  ) { }

  toggleCustomTip() {
    this.customTipHidden = !this.customTipHidden;
  }

  calculateTip(change: any) {
    console.log(change);
    if(change.name && change.name === 'Custom') {
      this.customTipHidden = false;
      return;
    }else{
      this.customTipHidden = true;
      this.orderWithPayment.updateTip(change.value);
      this.tipPercent = change.value;
      this.orderService.updateTip(this.orderHash, change.value, 'tip-component-calculate').pipe(takeUntil(this.destroySubject)).subscribe(data => {
        console.log(data);
        this.refresh.emit(true);
      });
    }
  }

  calculateCustomTip(tip: number) {
    this.orderService.updateCustomTip(this.orderHash, tip, 'tip-component-calculate-custom').pipe(takeUntil(this.destroySubject)).subscribe(data => {
      this.orderWithPayment.updateTipCustom(+tip);
      this.toggleCustomTip();
      this.refresh.emit(true);
    });
  }

  ngOnInit() {
    // const storeTipOptions = this.orderWithPayment.getTipPercentOptions();
    // if (storeTipOptions.length > 0) {
    //   this.tips = storeTipOptions.map(t => {
    //     return {name: `${t.percent}%`, value: +t.percent};
    //   });
    //   this.tips.push({name: 'Custom', value: 0});
    // }
    this.tipPercent = this.orderWithPayment.getTipPercent();
    console.log(this.tipPercent);
    console.log('orderWithPayment', this.orderWithPayment);
  }

  ngOnChanges() {
    // this.ngOnInit();
  }

  ngOnDestroy() {
    this.destroySubject.next();
  }

}
