<div class="rounded-md bg-white border border-gray-300 p-5 flex flex-col gap-3">
   <p class="text-xl mb-3">Attendant Settings</p>
   <div class="grid grid-cols-8 gap-4">
      <div class="">
         <svg xmlns="http://www.w3.org/2000/svg" height="28" viewBox="0 0 24 24" width="28">
            <path d="M0 0h24v24H0z" fill="none" />
            <path
               d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"
               fill="#1a70e0" />
         </svg>
      </div>
      <div class="col-span-7">
         <span>{{ store?.ivr_phone }}</span>
      </div>
   </div>
   <div class="grid grid-cols-8 gap-4">
      <img class="w-8" src="../../../assets/outline_record_voice_over_black_18dp.png">
      <div class="flex flex-col col-span-6">
         <p class="text-base">Attendant voice</p>
         <!-- <p class="text-sm text-gray-700">{{attendantSettings.voice|titlecase}}</p> -->
         <div *ngIf="!playerVisible" class="audio-box">
            <!-- <ang-music-player [playlist]="mp3Link"></ang-music-player> -->
            <ang-music-player *ngIf="attendantSettings.mp3_link" [audioList]=mp3Link></ang-music-player>
         </div>
         <div *ngIf="playerVisible" class="audio-box">
            <ang-music-player [audioList]=mp3Link></ang-music-player>
         </div>
      </div>
      <mat-icon class="edit-icon material-icons-outlined" (click)="edit('voice')">edit</mat-icon>
   </div>
   <div class="grid grid-cols-8 gap-4">
      <img class="w-8" src="../../../assets/open-icon.png">
      <div class="col-span-6">
         <p class="text-base">Greeting Message</p>
         <p class="text-sm text-gray-700">
            <span *ngIf="!attendantSettings.use_welcome_audio">{{attendantSettings.welcome_message}}</span>
            <span *ngIf="attendantSettings.use_welcome_audio" class="audio-box">
               <!-- <a [href]="attendantSettings.welcome_audio">Greeting Message Audio</a> -->
               <!-- <ang-music-player [playlist]="welcomeAudio"></ang-music-player> -->
               <ang-music-player *ngIf="!playerVisible" [audioList]=welcomeAudio></ang-music-player>
               <ang-music-player *ngIf="playerVisible" [audioList]=welcomeAudio></ang-music-player>

            </span>
         </p>
      </div>
      <mat-icon class="edit-icon material-icons-outlined" (click)="edit('open')">edit</mat-icon>
   </div>
   <div class="grid grid-cols-8 gap-4">
      <img class="w-8" src="../../../assets/closed-icon.png">
      <div class="col-span-6">
         <p class="text-base">Closed Message</p>
         <p class="text-sm text-gray-700">
            <span *ngIf="!attendantSettings.use_closed_hours_audio">{{attendantSettings.closed_hours_message}}</span>
            <span *ngIf="attendantSettings.use_closed_hours_audio" class="audio-box">
               <!-- <a [href]="attendantSettings.closed_hours_audio">Closed Hours Audio</a> -->
               <!-- <ang-music-player [playlist]="closedHoursAudio"></ang-music-player> -->
               <ang-music-player *ngIf="!playerVisible" [audioList]=closedHoursAudio></ang-music-player>
               <ang-music-player *ngIf="playerVisible" [audioList]=closedHoursAudio></ang-music-player>
            </span>
         </p>
      </div>
      <mat-icon class="edit-icon material-icons-outlined" (click)="edit('closed')">edit</mat-icon>
   </div>
</div>