<!-- <div class=" overflow-x-hidden pb-16 relative	overflow-y-hidden"> -->
<div class=" overflow-x-hidden relative	overflow-y-hidden">
  <div class="bg-white w-full mt-5 mr-5 mb-5 overflow-x-hidden rounded-md"
    [ngClass]="{ 'hide-tabs': editItem || stepNumber > 1 }" id="new-order-container" #container
    [style.height]="divHeight">
    <form [formGroup]="occasionForm">
      <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="selected" #tabs (selectedTabChange)="tabChange($event)"
        class="w-full">
        <mat-tab [label]=" occasionForm.controls.occasion.value ||  'ORDER OCCASION' " [ngClass]="{ active: true }"
          [disabled]="!validUserData?.sessionId">
          <div class="w-full">

            <div class="flex flex-row gap-3 items-stretch w-full p-5 xs:p-3 xs:gap-1.5 xs:overflow-x-scroll dark-bg-color">
              <button class="border border-gray-300 h-12 !w-[120px] !min-w-[100px] rounded shadow-lg xl:grow lg:grow md:grow text-lg xs:!text-base xs:!h-12 xs:!p-1 dark-bg-color"
                #groupOcc [ngClass]="{
                      'lab-bg-blue text-white shadow-blue-200': selectedOccasion === occasionName,
                      'bg-white': selectedOccasion !== occasionName}" *ngFor="let occasionName of occasions"
                (click)="onOccasionSelect(occasionName)" [ngclass]="{'disabled-cta': !validUserData?.sessionId}"
                [style.color]="!validUserData?.sessionId ? '#8f8f8f' : ''"
                [style.background-color]="!validUserData?.sessionId ? '#e0e0e0' : ''">{{ occasionName |
                titlecase }}
              </button>
            </div>
          </div>

          <div *ngIf="selectedOccasion === 'DINE-IN' || (selectedOccasion === 'TABLESIDE' && tables$.length)"
            class="p-5 overflow-x-auto xs:p-3 dark-bg-color" [ngStyle]="{'max-height': maxHeightNewOrder}">
            <div class="grid grid-cols-5 xs:grid-cols-3 gap-2">
              <!-- <input
                *ngFor="let table of tables$"
                [disabled]="!validUserData?.sessionId || table.occupied"
                type="button"
                class="border border-gray-300 hover:bg-gray-100 w-full h-20 bg-white drop-shadow-md xs:!text-xs xs:rounded-md"
                [value]="table.table_name"
                [ngClass]="{ 'activeTable': selectedTable ===  table.table_name}"
                (click)="onTableSelect(table.table_name); openDialog()"
              /> -->
              <input *ngFor="let table of tables$"
                [ngClass]="{ 'disabled-cta': !validUserData?.sessionId || table.occupied}"
                [style.color]="!validUserData?.sessionId ? '#8f8f8f' : ''"
                [style.background-color]="!validUserData?.sessionId ? '#e0e0e0' : ''" type="button"
                class="border border-gray-300 hover:bg-gray-100 w-full h-20 bg-white drop-shadow-md xs:!text-xs xs:rounded-md dark-bg-color"
                [value]="table.table_name"
                [ngClass]="{ 'activeTable': selectedTable ===  table.table_name}"
                (click)="onTableSelect(table.table_name);"
              />
            </div>
          </div>

          <ng-container *ngIf="selectedOccasion === 'CURBSIDE'">
            <div class="flex flex-row items-center p-5 dark-bg-color">
              <mat-form-field subscriptSizing="dynamic" class="w-96 mr-3">
                <mat-label>Vehicle Details</mat-label>
                <input matInput placeholder="Vehicle Details" formControlName="curbside_vehicle_id"
                  [disabled]="!validUserData?.sessionId" #vehicleId
                  (keyup.enter)="onCurbsideSelect($event.target.value)" />
              </mat-form-field>
              <button [disabled]="!validUserData?.sessionId" class="curbside-cta apply-btn-colr" mat-flat-button
                color="primary" (click)="onCurbsideSelect(vehicleId.value)">
                Update
              </button>
            </div>
          </ng-container>
          <!--  Delivery Address  -->
          <div *ngIf="selectedOccasion === 'DELIVERY'" class="p-5 overflow-y-auto dark-bg-color" [ngClass]="{'fullscreen-element': true}">
             <div [expanded]="true" hideToggle disabled
                [ngClass]="{ 'disabled-cta': !validUserData?.sessionId }" class="new-dlvry-design">
                <div fxLayout="row" fxLayout.md="row" fxLayout.xs="column" fxFlex="100" fxLayoutAlign="center" fxLayoutGap="10px"
                  class="" (mousedown)="preventInputBlur($event)">
                  <div class="flex flex-col" fxHide.xs>
                    <mat-form-field appearance="fill">
                      <mat-label>Enter Mobile Number</mat-label>
                      <input #phone matInput autofocus formControlName="phone_number"
                        placeholder="Enter Mobile Number"
                        [errorStateMatcher]="errorMatcher" (input)="onNumberInputChange($event)" (focus)="onInputNumberFocus($event)" inputmode="none" maxlength="10" required>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                      <mat-label>Enter Customer Name</mat-label>
                      <input matInput placeholder="Enter Customer Name" autofocus [errorStateMatcher]="errorMatcher"
                        (keyup)="updateOrderName($event.target.value)" formControlName="order_name" #orderName (input)="onInputChange($event)" (focus)="onInputFocus($event)" inputmode="none" required>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                      <mat-label>Enter Delivery Address</mat-label>
                      <input class="remove-border location-size location-color"  placeholder="Enter Delivery Address" matInput matGoogleMapsAutocomplete formControlName="delivery_address"
                        [ngClass]="{ 'disabled-cta': !validUserData?.sessionId }" [address]=retrievedAddress
                        (onAutocompleteSelected)="onAutocompleteSelected($event)"
                        (onLocationSelected)="onLocationSelected($event)" #deliveryAddress (input)="onInputChange($event)" (focus)="onInputFocus($event)" inputmode="none" required>
                    </mat-form-field>
                  </div>
                  <!-- FOR MOBILE VIEW -->
                  <div fxFlex.xs="92" fxHide fxShow.xs>
                    <mat-form-field appearance="fill" class="xs:w-full">
                      <mat-label>Enter Mobile Number</mat-label>
                      <input #phone matInput autofocus formControlName="phone_number"
                        placeholder="Enter Mobile Number"
                        [errorStateMatcher]="errorMatcher" (input)="onNumberInputChange($event)" (focus)="onInputNumberFocus($event)" maxlength="10" required>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="xs:w-full">
                      <mat-label>Enter Customer Name</mat-label>
                      <input matInput placeholder="Enter Customer Name" autofocus [errorStateMatcher]="errorMatcher"
                        (keyup)="updateOrderName($event.target.value)" formControlName="order_name" #orderName (input)="onInputChange($event)" (focus)="onInputFocus($event)" required>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="xs:w-full">
                      <mat-label>Enter Delivery Address</mat-label>
                      <input class="remove-border location-size location-color"  placeholder="Enter Delivery Address" matInput matGoogleMapsAutocomplete formControlName="delivery_address"
                        [ngClass]="{ 'disabled-cta': !validUserData?.sessionId }" [address]=retrievedAddress
                        (onAutocompleteSelected)="onAutocompleteSelected($event)"
                        (onLocationSelected)="onLocationSelected($event)" #deliveryAddress (input)="onInputChange($event)" (focus)="onInputFocus($event)" required>
                    </mat-form-field>
                  </div>
                  <div fxFlex="56" fxFlex.md="55" class="key-num-char" fxHide.xs>
                    <div class="simple-keyboard hot-button" style="width: 100%;" (mousedown)="preventInputBlur($event)"></div>
                  </div>
                  <div fxFlex="28" fxFlex.md="27" style="text-align: center;" class="number-kbrd" fxHide.xs>
                    <div class="myFavouriteClass hot-button" (mousedown)="preventInputBlur($event)"></div>
                  </div>
                </div>
             </div>
              <div [expanded]="true" hideToggle disabled
                [ngClass]="{ 'disabled-cta': !validUserData?.sessionId }" class="remove-padding">
                <div fxLayout="row" fxLayout.md="row" fxLayout.xs="column"fxFlex="100" fxLayoutAlign="center" fxLayoutGap="10px"
                  class="address tab-address">
                  <div fxFlex="25" fxFlex.md="25" fxFlex.xs="50" class="delivery-tab-order">
                    <span class="title head-tetx hot-button">Previous Orders</span>
                    <div class="scroll-list-address">
                      <div class="delivery-address-list" >
                        <div *ngFor="let order_list of previousOrderList">
                          <div class="delivery-address list-marg" [ngClass]="{'order-view': order_list.order_hash == previoudOrderOverview?.bot_order?.order_hash}">
                            <div class="address-list-text text-clr" (click)="getPreviousOrder(order_list.order_hash)">
                              {{order_list.submitted_at | date:'EEE MMM hh:mm:ss a' }}  <span style="margin-left: 15px;">Order{{order_list.order_counter}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div fxFlex="20" fxFlex.md="25" fxFlex.xs="50" class="delivery-tab-order">
                    <span class="title head-tetx">Order Details</span>
                    <div *ngIf="previoudOrderOverview">
                      <div style="overflow-y: auto;max-height:270px">
                        <app-order-item-breakup [orderWithPayment]="previoudOrderOverview"></app-order-item-breakup>
                      </div>
                  </div>

                  </div>
                  <div fxFlex="25" fxFlex.md="25" fxFlex.xs="50" class="delivery-tab-order">
                    <span class="title head-tetx text-clr">Previous Delivery Adresses</span>
                      <div class="scroll-list-address">
                        <div class="delivery-address-list" >
                          <div *ngFor="let address of addressList">
                            <div class="delivery-address list-marg"
                              *ngIf="address.delivery_address != null && address.delivery_address != ''"
                              (click)="getAutoAddress(address.delivery_address)">
                              <div class="address-list-text text-clr">
                                {{address.delivery_address}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div fxFlex="25" fxFlex.md="25" fxFlex.xs="50" class="map map-pos address-form map-guide !mr-2.5 delivery-tab-order">
                    <agm-map class="w-full h-full" [latitude]="latitude" [longitude]="longitude"
                  [scrollwheeh-screenl]="false" [zoom]="zoom" [mapTypeControlOptions]="mapTypeControlOptions">
                  <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
                </agm-map>
                  </div>
                </div>
              </div>
          </div>
        </mat-tab>
        <mat-tab [label]="occasionForm.controls.time.value || 'ORDER TIME'" [disabled]="!validUserData?.sessionId">

          <div class="grid grid-cols-2 gap-3 w-full p-5 dark-bg-color">
            <button class="border border-gray-300 h-12 rounded shadow-lg grow text-lg xs:!h-16 xs:!text-base xs:!p-1 dark-bg-color"
              [ngClass]="{
                      'lab-bg-blue text-white shadow-blue-200': selectedTime === 'ASAP',
                      'bg-white': selectedTime !== 'ASAP'}" (click)="onTimeSelect('ASAP');tabs.selectedIndex = 2"
              [disabled]="!validUserData?.sessionId">ASAP</button>
            <button class="border border-gray-300 h-12 rounded shadow-lg grow text-lg xs:!text-base xs:!h-16 xs:!p-1 dark-bg-color"
              [ngClass]="{
                      'lab-bg-blue text-white shadow-blue-200': selectedTime === 'LATER',
                      'bg-white': selectedTime !== 'LATER'}" (click)="onTimeSelect('LATER')"
              [disabled]="!validUserData?.sessionId" [hidden]="selectedOccasion === 'Tableside'">Schedule for
              Later</button>
          </div>

          <div class="grid grid-cols-2 gap-3 p-5" *ngIf="selectedTime === 'LATER'">
            <mat-form-field class="w-full" appearance="fill">
              <mat-label>Date</mat-label>
              <input matInput [matDatepicker]="picker" formControlName="schedule_date" [min]="minDate"
                (dateChange)="updateHoursBasedOnSelectedDate($event)" [disabled]="!validUserData?.sessionId" />
              <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="!validUserData?.sessionId">
              </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="w-full" appearance="fill">
              <mat-label>Time</mat-label>
              <mat-select formControlName="schedule_time" [compareWith]="hourCompare"
                [disabled]="!validUserData?.sessionId">
                <mat-option *ngFor="let time of timeRange" [value]="time | date: 'hh:mm a'"
                  (click)="onTimeSelectClick($event)">
                  {{ time | date: "hh:mm a" }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-tab>
        <mat-tab label="BUILD ORDER" [disabled]="!validUserData?.sessionId">
          <app-pos-menu (refresh)="refresh.emit(true)" (selectTab)="tabGroup.selectedIndex = $event"
            (parserMenuItems)="getParserMenuItems($event)" [drawerOpened]="drawerOpened"
            (toggleShowSummaryDrawer)="toggleShowSummaryDrawer.emit()" [validUserData]="validUserData"
            (onInitEventQuickEntry)="receiveShowQuickEntry($event)"></app-pos-menu>
        </mat-tab>
      </mat-tab-group>
    </form>
  </div>

  <div *ngIf="showQuickEntry" class="p-2.5 bg-white border border-gray-300 mt-5 show-quick-entry xs:p-1">
    <div class="grid gap-1 items-center xs:grid-cols-3" [ngClass]="{
      'grid-cols-4': !store.feature_flag['cash_drawer_management'] && !store.feature_flag['barcode_scanner_feature'],
      'grid-cols-5': (store.feature_flag['cash_drawer_management'] || store.feature_flag['barcode_scanner_feature']) && !(store.feature_flag['cash_drawer_management'] && store.feature_flag['barcode_scanner_feature']),
      'grid-cols-6': store.feature_flag['cash_drawer_management'] && store.feature_flag['barcode_scanner_feature']
    }">

      <div class="w-full" *ngIf="store.feature_flag['barcode_scanner_feature']">
        <button [ngClass]="{ 'disabled-cta': !validUserData?.sessionId }"
          class="w-full !h-20 xs:!h-14 text-lg xs:!text-xs xs:rounded-md border border-gray-300 bg-white hover:bg-gray-100 dark-bg-color"
          (click)="startScanningItem()">Scan Item</button>
      </div>
      <div class="w-full" *ngIf="store.feature_flag['cash_drawer_management']">
        <button [ngClass]="{ 'disabled-cta': !validUserData?.sessionId }"
          class="w-full !h-20 xs:!h-14 text-lg xs:!text-xs xs:rounded-md border border-gray-300 bg-white hover:bg-gray-100 dark-bg-color"
          (click)="checkManageDrawerPer()">Manage Drawer</button>
      </div>
      <div class="w-full">
        <button [ngClass]="{ 'disabled-cta': !validUserData?.sessionId }"
          class="w-full !h-20 xs:!h-14 text-lg xs:!text-xs xs:rounded-md border border-gray-300 bg-white hover:bg-gray-100 dark-bg-color"
          (click)="openQuickEntry()">Quick Entry</button>
      </div>
      <div class="w-full">
        <button [ngClass]="{ 'disabled-cta': !validUserData?.sessionId }"
          class="w-full !h-20 xs:!h-14 text-lg xs:!text-xs xs:rounded-md border border-gray-300 bg-white hover:bg-gray-100 dark-bg-color"
          (click)="openDiscount()">Discount</button>
      </div>
      <div class="w-full">
        <button [ngClass]="{ 'disabled-cta': !validUserData?.sessionId }"
          class="w-full !h-20 xs:!h-14 text-lg xs:!text-xs xs:rounded-md border border-gray-300 bg-white hover:bg-gray-100 dark-bg-color"
          (click)="openDrawerPermissiion()">Open Drawer</button>
      </div>
      <div class="w-full">
        <button [ngClass]="{ 'disabled-cta': !validUserData?.sessionId }"
          class="w-full !h-20 xs:!h-14 text-lg xs:!text-xs xs:rounded-md border border-gray-300 bg-white hover:bg-gray-100 dark-bg-color"
          (click)="rePrintLastReceipt()">Reprint Last Receipt</button>
      </div>
    </div>
  </div>
</div>
