export const environment = {
  production: true,
  apiUrl: 'https://beta.labrador.ai',
  websocketHost:'wss://backend.order.labrador.ai',
  stripeClientId: 'ca_DIeb3fjLiRc99bQudxKkUo7bWOQXxm4A',
  orderAppUrl: 'https://order.beta.labrador.ai',
  placesAPIKey: 'AIzaSyDRmxxVBcS640504Q28_fP9JJv8iH-Ki3A',
  firebase:{
    apiKey: "AIzaSyBKHYGsT9uTznTz5FOFxqVxjXeXyyUxkH0",
    authDomain: "labrador-frontend.firebaseapp.com",
    databaseURL: "https://labrador-frontend.firebaseio.com",
    projectId: "labrador-frontend",
    storageBucket: "labrador-frontend.appspot.com",
    messagingSenderId: "917402468037"
  },
  storeArr: [645, 685],
  leaflogixApiEndpoint: "https://beta.labrador.ai",
};
