import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatDialogRef} from '@angular/material/dialog';
import * as moment from 'moment';
import { FormUtils } from 'src/app/_helpers/form-utils';
import { Store } from 'src/app/_models/store';
import { StoreService } from 'src/app/_services/store.service';

import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
@Component({
  selector: 'app-holidays',
  templateUrl: './holidays.component.html',
  styleUrls: ['./holidays.component.scss']
})
export class HolidaysComponent implements OnInit {

  holiday_names = [
    "New Year's Day",
    "Martin Luther King Jr. Day",
    "Valentine's Day",
    "Presidents' Day",
    "St. Patrick's Day",
    "Cesar Chavez Day",
    "Easter Sunday",
    "Earth Day",
    "Arbor Day",
    "Cinco de Mayo",
    "Mother's Day",
    "Memorial Day",
    "Juneteenth National Independence Day",
    "Father's Day",
    "Independence Day",
    "Labor Day",
    "Patriot Day",
    "Constitution Day and Citizenship Day",
    "Columbus Day / Indigenous Peoples' Day",
    "Halloween",
    "Veterans Day",
    "Black Friday",
    "Christmas Eve",
    "Christmas Day",
    "New Year's Eve"
]

  isLoading = false;
  form;
  store:Store;

  hours;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<HolidaysComponent>,
    private formUtils:FormUtils,  
    private storeService: StoreService,
    @Inject(MAT_DIALOG_DATA) public data: any,) {
      this.hours = formUtils.buildTimeOptions();
    }

  ngOnInit() {
    this.store = this.data.store;
    this.form = this.formBuilder.array(this.store.holiday_list.map(sh => {
     

      return this.formBuilder.group({
        store_id: this.store.id,
        is_open: sh.is_open,
        holiday_name:sh.holiday_name
      })
    }))

  }

  hourCompare(left, right){
    return left.value === right.value;
  }


  getTimeControl(key, i){
    return (this.form as FormArray).controls[i].get(key)
  }

  
  toggle(event, i) {
    const isOpen = event.checked;
    this.form.controls[i].patchValue({ is_open: isOpen });
  
    if (!isOpen) {
      this.getTimeControl('from', i).disable();
      this.getTimeControl('to', i).disable();
    } else {
      this.getTimeControl('from', i).enable();
      this.getTimeControl('to', i).enable();
    }
    this.getTimeControl('from', i).updateValueAndValidity();
    this.getTimeControl('to', i).updateValueAndValidity();
  }
  

  addNew(){
    (this.form as FormArray).push(this.formBuilder.group({
      store_id: this.store.id,
      is_open:false,
      holiday_name: [null, Validators.required],
    }))
  }

  delete(i){
    (this.form as FormArray).removeAt(i)
  }
  
  onSubmit(){
    let payload = {
      store_id: this.store.id,
      holiday: this.form.value
    }
    this.storeService.updateHoliday(payload).subscribe(data => {
      this.dialogRef.close()
    })
  }

}
