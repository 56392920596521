<!-- <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar> -->
<form class="store-setting">
  <div class="card" [ngClass.xs]="{'card-xs': true, 'card': true}">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <mat-accordion>
      <mat-expansion-panel [expanded]="panelOpenState" class="store-setting-panel-body">

        <mat-expansion-panel-header>
          <mat-panel-title>
            <h2 class="dim-color lean-font-heading">Service settings</h2>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="form-group" style="margin-bottom: 5px;">
          <label class="dim-color lean-font ">Service on</label>
          <mat-slide-toggle color="primary" class="example-margin" style="float: right;"
            [checked]="store['service_toggle']" (change)="onToggleChange($event, 'service_toggle')">
          </mat-slide-toggle>
        </div>
        <br />
        <div class="form-group">
          <label class="dim-color lean-font">Pause for
            <span class="input-time">
              <input type="text" (input)="onInputChange($event, 'pause')" [(ngModel)]="numericValue"
                [ngModelOptions]="{standalone: true}" class="minutes-field">
            </span>
            minutes
          </label>
          <mat-button-toggle class="pause-btn xs:!ml-2" #minutePausetoggleButton
            (change)="pauseStoreService(2, minutePausetoggleButton.checked)" [checked]="store.pause_store"
            [class.active]="minutePausetoggleButton.checked" [disabled]="!numericValue">Pause</mat-button-toggle>
        </div>
        <br />
        <div class="form-group toggle-active">
          <mat-button-toggle class="whole-day-btn" #dayPauseToggle
            (change)="pauseStoreService(1, dayPauseToggle.checked)" [checked]="store.pause_store_whole_day"
            [class.active]="dayPauseToggle.checked">Pause For The Rest Of The Day</mat-button-toggle>
        </div>
        <br />
        <div class="timer" *ngIf="(store.pause_store || store.pause_store_whole_day) && isCountdownGreaterThanZero()"
          [style.backgroundColor]="store.count_down_timer_color">
          {{countdownFormatted}}
        </div>
      </mat-expansion-panel>

    </mat-accordion>
    <hr class="short hr_style" />
    <mat-accordion>
      <mat-expansion-panel [expanded]="panelOpenState" class="store-setting-panel-body">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h2 class="dim-color lean-font-heading">Payment settings</h2>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="form-group">
          <label class="dim-color lean-font">Accept cash / pay in person</label>
          <mat-slide-toggle class="example-margin" style="float: right;" [checked]="store['enable_cash_payment']"
            (change)="onToggleChange($event, 'enable_cash_payment')">
          </mat-slide-toggle>
        </div>
        <br />
        <div class="form-group">
          <label class="dim-color lean-font">Accept debit & credit cards</label>
          <mat-slide-toggle class="example-margin" style="float: right;" [checked]="store['has_card_payment']"
            (change)="onToggleChange($event, 'has_card_payment')">
          </mat-slide-toggle>
        </div>
        <br />
        <div class="form-group">
          <label class="dim-color lean-font">Enable tipping</label>
          <mat-slide-toggle class="example-margin" style="float: right;" [checked]="store['toggle_tip']"
            (change)="onToggleChange($event, 'toggle_tip')">
          </mat-slide-toggle>
        </div>
        <br />
        <div class="form-group">
          <label class="dim-color lean-font">Enable POS Pay Later</label>
          <mat-slide-toggle class="example-margin" style="float: right;" [checked]="store['enable_pos_pay_later']"
            (change)="onToggleChange($event, 'enable_pos_pay_later')">
          </mat-slide-toggle>
        </div>
        <br />
        <div class="form-group">
          <label class="dim-color lean-font">Consolidate Taxes & Fees</label>
          <mat-slide-toggle class="example-margin" style="float: right;" [checked]="store['consolidate_taxes_and_fees']"
            (change)="onToggleChange($event, 'consolidate_taxes_and_fees')">
          </mat-slide-toggle>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <hr class="short hr_style" />
    <mat-accordion>
      <mat-expansion-panel [expanded]="panelOpenState" class="store-setting-panel-body">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h2 class="dim-color lean-font-heading">Menu settings</h2>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="form-group">
          <label class="dim-color lean-font">Expand menu category by default</label>
          <mat-slide-toggle class="example-margin" style="float: right;" [disabled]="!store['has_structured_menu']"
            [checked]="store['feature_flag']['collapse_menu_category'] && store['has_structured_menu']"
            (change)="updateFeatureFlag('collapse_menu_category', $event.checked)">
          </mat-slide-toggle>
        </div>
        <br />
        <div class="form-group">
          <label class="dim-color lean-font ">Expand menu subcategory by default</label>
          <mat-slide-toggle class="example-margin" style="float: right;" [disabled]="!store['has_structured_menu']"
            [checked]="store['feature_flag']['collapse_menu_subcategory'] && store['has_structured_menu']"
            (change)="updateFeatureFlag('collapse_menu_subcategory', $event.checked)">
          </mat-slide-toggle>
        </div>
        <br />

        <div class="form-group">
          <label class="dim-color lean-font ">Expand item modifiers by default</label>
          <mat-slide-toggle class="example-margin" style="float: right;" [disabled]="!store['has_structured_menu']"
            [checked]="store['feature_flag']['collapse_item_modifiers'] && store['has_structured_menu']"
            (change)="updateFeatureFlag('collapse_item_modifiers', $event.checked)">
          </mat-slide-toggle>
        </div>
        <br />

        <div class="form-group">
          <label class="dim-color lean-font ">Enable Order Ready</label>
          <mat-slide-toggle class="example-margin" style="float: right;"
            [checked]="store['feature_flag']['enable_order_ready']"
            (change)="updateFeatureFlag('enable_order_ready', $event.checked)">
          </mat-slide-toggle>
        </div>
        <br />
        <div class="form-group flex flex-row justify-between items-center">
          <label class="dim-color lean-font">Auto Accept Orders</label>
          <mat-button-toggle-group (change)="updateFeatureFlag('auto_accept_orders', $event.value)"
            [value]="selectedAutoManual">
            <mat-button-toggle value="auto" class="auto-manual" style="width: 80px;height:40px;">AUTO
            </mat-button-toggle>
            <mat-button-toggle value="manual" class="auto-manual" style="width: 80px;height:40px;">MANUAL
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <br />
        <div class="form-group">
          <label class="dim-color lean-font ">Stand Alone Gift Card UI/UX</label>
          <mat-slide-toggle class="example-margin" style="float: right;"
            [checked]="store['feature_flag']['stand_alone_gift']"
            (change)="updateFeatureFlag('stand_alone_gift', $event.checked)">
          </mat-slide-toggle>
        </div>
        <br />
        <div class="form-group">
          <label class="dim-color lean-font ">Enable Reorder</label>
          <mat-slide-toggle class="example-margin" style="float: right;" [checked]="store['feature_flag']['reorder']"
            (change)="updateFeatureFlag('reorder', $event.checked)">
          </mat-slide-toggle>
        </div>
        <div class="form-group flex flex-row justify-between items-center pt-5">
          <label class="dim-color lean-font">Modifier Display</label>
          <mat-button-toggle-group (change)="updateFeatureFlag('modifiers_display_hv', $event.value)"
            [value]="selectedVerticalHorizontal">
            <mat-button-toggle value="vertical" class="auto-manual" style="width: 94px;height:40px;">VERTICAL
            </mat-button-toggle>
            <mat-button-toggle value="horizontal" class="auto-manual" style="width: 110px;height:40px;">HORIZONTAL
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <div *ngIf="store?.modules.includes('POS')">
      <hr class="short hr_style" />
      <mat-accordion>
        <mat-expansion-panel [expanded]="panelOpenState" class="store-setting-panel-body">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h2 class="dim-color lean-font-heading">POS settings</h2>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <span *ngIf="!store.feature_flag['multiple_default_load']">
            <div class="form-group">
              <label class="dim-color lean-font">Default Load</label>
              <mat-slide-toggle class="example-margin" style="float: right;"
                [checked]="store && store.pos_setting && store.pos_setting['default_load']"
                (change)="onToggleChange($event,'default_load')">
              </mat-slide-toggle>
            </div>
            <br />
            <mat-list class="store-info">
              <mat-list-item (click)="openDialog(store.pos_setting)">
                <p matListItemLine class="row !flex flex-row justify-between w-full">
                  <span class="w-full">Default Order Occasions</span>
                  <span class="w-full text-right"
                    *ngIf="store && store.pos_setting && store.pos_setting['default_order_occasion']">
                    {{store.pos_setting['default_order_occasion']}}</span>
                </p>

                <p matListItemLine class="row !flex flex-row justify-between w-full">
                  <span class="w-full">Default Time</span>
                  <span class="w-full text-right"
                    *ngIf="store && store.pos_setting && store.pos_setting['default_time']">
                    {{this.store.pos_setting["default_time"]}}</span>
                </p>
              </mat-list-item>
              <br />
              <div class="form-group">
                <label class="dim-color lean-font">Filter By PIN - Closed Orders</label>
                <mat-slide-toggle class="example-margin" style="float: right;"
                  [checked]="store && store.pos_setting && store.pos_setting['closed_orders']"
                  (change)="onToggleChange($event,'closed_orders')">
                </mat-slide-toggle>
              </div>
              <br />
              <div class="form-group">
                <label class="dim-color lean-font">Filter By PIN - Open Orders</label>
                <mat-slide-toggle class="example-margin" style="float: right;"
                  [checked]="store && store.pos_setting && store.pos_setting['open_orders']"
                  (change)="onToggleChange($event,'open_orders')">
                </mat-slide-toggle>
              </div>
              <br />
            </mat-list>
          </span>
          <span *ngIf="store.feature_flag['multiple_default_load']">
            <span *ngFor="let setting of store.pos_setting">
              <mat-accordion>
                <mat-expansion-panel [expanded]="defaultPanelOpenState">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <h2 class="dim-color lean-font"> Default Load for {{ getTerminalName(setting['terminal_id']) | titlecase}}</h2>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="form-group">
                    <label class="dim-color lean-font">Default Load</label>
                    <mat-slide-toggle class="example-margin" style="float: right;" [checked]="setting['default_load']"
                      (change)="onPosSettingToggleChange($event,'default_load', setting.pos_setting_id)">
                    </mat-slide-toggle>
                  </div>
                  <br />

                  <mat-list class="store-info">
                    <mat-list-item (click)="openDialog(setting)">
                      <p matListItemLine class="row !flex flex-row justify-between w-full">
                        <span class="w-full">Default Order Occasions</span>
                        <span class="w-full text-right" *ngIf="setting['default_order_occasion']">
                          {{setting['default_order_occasion']}}</span>
                      </p>

                      <p matListItemLine class="row !flex flex-row justify-between w-full">
                        <span class="w-full">Default Time</span>
                        <span class="w-full text-right" *ngIf="setting['default_time']">
                          {{setting["default_time"]}}</span>
                      </p>

                      <p matListItemLine class="row !flex flex-row justify-between w-full">
                        <span class="w-full">Station</span>
                        <span class="w-full text-right" *ngIf="setting['terminal_id']">
                          {{ getTerminalName(setting['terminal_id']) }}</span>
                      </p>
                    </mat-list-item>
                    <br />
                    <div class="form-group">
                      <label class="dim-color lean-font">Filter By PIN - Closed Orders</label>
                      <mat-slide-toggle class="example-margin" style="float: right;"
                        [checked]="setting['closed_orders']"
                        (change)="onPosSettingToggleChange($event,'closed_orders', setting.pos_setting_id)">
                      </mat-slide-toggle>
                    </div>
                    <br />
                    <div class="form-group">
                      <label class="dim-color lean-font">Filter By PIN - Open Orders</label>
                      <mat-slide-toggle class="example-margin" style="float: right;" [checked]="setting['open_orders']"
                        (change)="onPosSettingToggleChange($event,'open_orders', setting.pos_setting_id)">
                      </mat-slide-toggle>
                    </div>
                    <br />
                  </mat-list>
                </mat-expansion-panel>
              </mat-accordion>
            </span>
          </span>
          <div class="form-group">
            <label class="dim-color lean-font">Force Clock Out
              <span class="input-time">
                <input type="text" (input)="onInputChange($event, 'force')" [(ngModel)]="clockOutMin"
                  [ngModelOptions]="{standalone: true}" class="minutes-field">
              </span>
              Minutes
            </label>
            <mat-slide-toggle class="example-margin" style="float: right;margin-top:12px"
              [checked]="store['feature_flag']['force_clock_out']"
              (change)="updateFeatureFlag('force_clock_out', $event.checked)">
            </mat-slide-toggle>
          </div>
          <br />
          <div class="form-group">
            <label class="dim-color lean-font">Cash Drawer Management</label>
            <mat-slide-toggle class="example-margin" style="float: right"
              [checked]="store['feature_flag']['cash_drawer_management']"
              (change)="updateFeatureFlag('cash_drawer_management', $event.checked)">
            </mat-slide-toggle>
          </div>
          <br />
          <div class="form-group">
            <label class="dim-color lean-font">Dark Mode</label>
            <mat-slide-toggle class="example-margin" style="float: right" [checked]="store['feature_flag']['dark_mode']"
              (change)="updateFeatureFlag('dark_mode', $event.checked)">
            </mat-slide-toggle>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <hr class="short hr_style" />

    <mat-accordion>
      <mat-expansion-panel [expanded]="panelOpenState" class="store-setting-panel-body">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h2 class="dim-color lean-font-heading">QR Code</h2>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="row center qr">
          <mat-button-toggle-group name="qrType" aria-label="QR Type" [(ngModel)]="qrType" (click)="getQRValue()">
            <mat-button-toggle value="sms" selected>SMS</mat-button-toggle>
            <mat-button-toggle value="url">Menu URL</mat-button-toggle>
            <mat-button-toggle value="gift">Gift URL</mat-button-toggle>
          </mat-button-toggle-group>

          <div class="row center" id="qrcode" style="margin-top: 1rem;" *ngIf="display">
            <mat-form-field>
              <mat-label>Enter QR Text</mat-label>
              <textarea matInput placeholder="Enter QR Text" [(ngModel)]="qr_text" name="qr_text"></textarea>
            </mat-form-field>
            <button (click)="getQRText()" [disabled]="!qr_text" mat-flat-button color="primary"
              style="margin-left: 1rem; bottom: .5rem;" name="button">Generate</button>
          </div>
        </div>

        <div class="row center" id="qrcode" *ngIf="qrDisplay != false">
          <img src="{{ imageUrl }}" alt='qr code'>
          <a href="{{ fileUrl }}" download="QR-SCAN-CODE">
            <br />
            (Click to download)
          </a>
        </div>

      </mat-expansion-panel>
    </mat-accordion>

    <hr class="short hr_style" />
    <mat-accordion class="printer-settings">
      <mat-expansion-panel [expanded]="panelOpenState" class="store-setting-panel-body">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h2 class="dim-color lean-font-heading">Printer settings</h2>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="form-group">
          <label class="dim-color lean-font">Checkout Manual Print Control</label>
          <mat-slide-toggle class="example-margin" style="float: right;"
            [checked]="store['feature_flag']['manual_print_standard_checkout']"
            (change)="updateFeatureFlag('manual_print_standard_checkout', $event.checked)">
          </mat-slide-toggle>
        </div>
        <br />
        <div class="form-group">
          <label class="dim-color lean-font">Pay Later Manual Print Control</label>
          <mat-slide-toggle class="example-margin" style="float: right;"
            [checked]="store['feature_flag']['manual_print_pay_later']"
            (change)="updateFeatureFlag('manual_print_pay_later', $event.checked)">
          </mat-slide-toggle>
        </div>
        <br />
        <div class="ind-printer-settings" *ngFor="let setting of printerSettings">
          <app-store-setting-printers class="pee" [printer]="setting" [store]="store"
            (parentFunction)="parentFunction()"></app-store-setting-printers>
        </div>

      </mat-expansion-panel>
    </mat-accordion>

    <hr class="short hr_style" />
    <mat-accordion>
      <mat-expansion-panel [expanded]="panelOpenState" class="store-setting-panel-body">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h2 class="dim-color lean-font-heading">Header settings</h2>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="form-group">
          <label class="dim-color lean-font">Header Message</label>
          <mat-slide-toggle class="example-margin" style="float: right;" [checked]="store && store.disclaimer_show"
            (change)="onToggleChangeSecond($event)">
          </mat-slide-toggle>
        </div>
        <br />
        <mat-action-list class="store-info">
          <mat-list-item class="padding-none" (click)="openDialogHeaderMessage()">
            <p matListItemLine class="row" [innerHTML]="disclaimerMessage"></p>
          </mat-list-item>
          <br />
        </mat-action-list>
      </mat-expansion-panel>
    </mat-accordion>
    <hr class="short hr_style" />
    <mat-accordion>
      <mat-expansion-panel [expanded]="panelOpenState" class="store-setting-panel-body">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h2 class="dim-color lean-font-heading">Order Ready Settings</h2>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <br />
        <mat-action-list class="store-info">
          <mat-list-item class="padding-none" (click)="openOrderReadyDialog()">
            <p matListItemLine class="row">
              <span class="order-ready-msg">Pickup </span>
              <span class="example-margin">{{store.occasion_pickup_message}}</span>
            </p>
            <p matListItemLine class="row">
              <span class="order-ready-msg">Dine-in</span>
              <span class="example-margin">{{store.occasion_dinein_message}}</span>
            </p>
            <p matListItemLine class="row">
              <span class="order-ready-msg">Delivery</span>
              <span class="example-margin">{{store.occasion_delivery_message}}</span>
            </p>
            <p matListItemLine class="row">
              <span class="order-ready-msg">Curbside</span>
              <span class="example-margin">{{store.occasion_curbside_message}}</span>
            </p>
            <p matListItemLine class="row">
              <span class="order-ready-msg">Tableside</span>
              <span class="example-margin">{{store.occasion_tableside_message}}</span>
            </p>
          </mat-list-item>
          <br />
        </mat-action-list>
      </mat-expansion-panel>
    </mat-accordion>
    <hr class="short hr_style" />
    <mat-accordion>
      <mat-expansion-panel [expanded]="panelOpenState" class="store-setting-panel-body">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h2 class="dim-color lean-font-heading">Gift Card Image</h2>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <hr class="short-1 hr_style" />
        <br />
        <mat-action-list [ngClass]="{'gift_image': is_giftCardImage , 'store-infoo':!is_giftCardImage}">
          <mat-list-item class="padding-none" (click)="giftCardDefaultImage()">
            <span *ngIf="is_giftCardImage; then showImage else showText"></span>
            <ng-template #showImage>
              <div class="image_sec">
                <picture class="img-responsive">
                  <img [src]="giftDefaultImage" />
                </picture>
              </div>
            </ng-template>
            <ng-template #showText class="textBlock">
              <div>
                <span style="color: dimgray;">No Image Availabe</span>
              </div>
            </ng-template>
          </mat-list-item>
          <br />
        </mat-action-list>
      </mat-expansion-panel>
    </mat-accordion>
    <hr class="short hr_style" />
    <mat-accordion *ngIf="!store['feature_flag']['service_fee_toggles_hide_show']">
      <mat-expansion-panel [expanded]="panelOpenState" class="store-setting-panel-body">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h2 class="dim-color lean-font-heading">Service Charges</h2>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <hr class="short-1 hr_style" />
        <br />
        <div class="form-group">
          <label class="dim-color lean-font">Enable Online Service Charge ($)</label>
          <mat-slide-toggle class="example-margin" style="float: right;"
            [checked]="store['enable_online_service_charge_dollar']"
            (change)="onToggleChange($event, 'enable_online_service_charge_dollar')">
          </mat-slide-toggle>
        </div>
        <p matListItemLine class="row">
          <span class="dim-color lean-font">Label</span>
          <span class="example-margin" style="float: right;">{{this.store.service_charge_label}}</span>
        </p>
        <p matListItemLine class="row">
          <span class="dim-color lean-font">Charge Amount</span>
          <span class="example-margin" style="float: right;">{{this.store.service_charge | currency:'USD'}}</span>
        </p>
        <br />
        <div class="form-group">
          <label class="dim-color lean-font">Enable POS Service Charge ($)</label>
          <mat-slide-toggle class="example-margin" style="float: right;"
            [checked]="store['enable_pos_service_charge_dollar']"
            (change)="onToggleChange($event, 'enable_pos_service_charge_dollar')">
          </mat-slide-toggle>
        </div>
        <p matListItemLine class="row">
          <span class="dim-color lean-font">Label</span>
          <span class="example-margin" style="float: right;">{{this.store.service_charge_label_pos}}</span>
        </p>
        <p matListItemLine class="row">
          <span class="dim-color lean-font">Charge Amount</span>
          <span class="example-margin" style="float: right;">{{this.store.service_charge_pos | currency:'USD'}}</span>
        </p>
        <br />
        <hr class="short-1 hr_style" />
        <br />
        <div class="form-group">
          <label class="dim-color lean-font">Enable Online Service Charge (%)</label>
          <mat-slide-toggle class="example-margin" style="float: right;"
            [checked]="store['enable_online_service_charge_percent']"
            (change)="onToggleChange($event, 'enable_online_service_charge_percent')">
          </mat-slide-toggle>
        </div>
        <p matListItemLine class="row">
          <span class="dim-color lean-font">Label</span>
          <span class="example-margin" style="float: right;">{{this.store.online_service_charge_title}}</span>
        </p>
        <p matListItemLine class="row">
          <span class="dim-color lean-font">Charge Amount</span>
          <span class="example-margin" style="float: right;">{{this.store.default_online_service_charge | number:
            '1.2-2'}}(%)</span>
        </p>
        <br />
        <!-- <hr class="short-1 hr_style" /> -->
        <br />
        <div class="form-group">
          <label class="dim-color lean-font">Enable POS Service Charge (%)</label>
          <mat-slide-toggle class="example-margin" style="float: right;"
            [checked]="store['enable_pos_service_charge_percent']"
            (change)="onToggleChange($event, 'enable_pos_service_charge_percent')">
          </mat-slide-toggle>
        </div>
        <p matListItemLine class="row">
          <span class="dim-color lean-font">Label</span>
          <span class="example-margin" style="float: right;">{{this.store.pos_service_charge_title}}</span>
        </p>
        <p matListItemLine class="row">
          <span class="dim-color lean-font">Charge Amount</span>
          <span class="example-margin" style="float: right;">{{this.store.default_pos_service_charge | number:
            '1.2-2'}}(%)</span>
        </p>
        <br />
        <hr class="short-1 hr_style" />
        <br />
        <div class="form-group">
          <label class="dim-color lean-font">Enable Online Credit Only Surcharge (%)</label>
          <mat-slide-toggle class="example-margin" style="float: right;"
            [checked]="store['enable_online_credit_service_charge_percent']"
            (change)="onToggleChange($event, 'enable_online_credit_service_charge_percent')">
          </mat-slide-toggle>
        </div>
        <p matListItemLine class="row">
          <span class="dim-color lean-font">Label</span>
          <span class="example-margin" style="float: right;">{{this.store.surcharge_label}}</span>
        </p>
        <p matListItemLine class="row">
          <span class="dim-color lean-font">Charge Amount</span>
          <span class="example-margin" style="float: right;">{{this.store.surcharge_percent}}(%)</span>
        </p>
        <br />
        <br />
        <div class="form-group">
          <label class="dim-color lean-font">Enable POS Credit Only Surcharge (%)</label>
          <mat-slide-toggle class="example-margin" style="float: right;"
            [checked]="store['enable_pos_credit_service_charge_percent']"
            (change)="onToggleChange($event, 'enable_pos_credit_service_charge_percent')">
          </mat-slide-toggle>
        </div>
        <p matListItemLine class="row">
          <span class="dim-color lean-font">Label</span>
          <span class="example-margin" style="float: right;">{{this.store.pos_surcharge_percent_label}}</span>
        </p>
        <p matListItemLine class="row">
          <span class="dim-color lean-font">Charge Amount</span>
          <span class="example-margin" style="float: right;">{{this.store.pos_surcharge_percent}}(%)</span>
        </p>
        <br />
        <hr class="short-1 hr_style" />
        <br />
        <!-- ENABLE DOLLAR SURCHARGE -->
        <div class="form-group">
          <label class="dim-color lean-font">Enable Online Credit Only Surcharge ($)</label>
          <mat-slide-toggle class="example-margin" style="float: right;"
            [checked]="store['enable_online_credit_service_charge_dollar']"
            (change)="onToggleChange($event, 'enable_online_credit_service_charge_dollar')">
          </mat-slide-toggle>
        </div>
        <p matListItemLine class="row">
          <span class="dim-color lean-font">Label</span>
          <span class="example-margin" style="float: right;">{{this.store.surcharge_label_dollar}}</span>
        </p>
        <p matListItemLine class="row">
          <span class="dim-color lean-font">Charge Amount</span>
          <span class="example-margin" style="float: right;">{{this.store.surcharge_dollar | currency:'USD'}}</span>
        </p>
        <br />
        <br />
        <div class="form-group">
          <label class="dim-color lean-font">Enable POS Credit Only Surcharge ($)</label>
          <mat-slide-toggle class="example-margin" style="float: right;"
            [checked]="store['enable_pos_credit_service_charge_dollar']"
            (change)="onToggleChange($event, 'enable_pos_credit_service_charge_dollar')">
          </mat-slide-toggle>
        </div>
        <p matListItemLine class="row">
          <span class="dim-color lean-font">Label</span>
          <span class="example-margin" style="float: right;">{{this.store.pos_surcharge_dollar_label}}</span>
        </p>
        <p matListItemLine class="row">
          <span class="dim-color lean-font">Charge Amount</span>
          <span class="example-margin" style="float: right;">{{this.store.pos_surcharge_dollar | currency:'USD'}}</span>
        </p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</form>