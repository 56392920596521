import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {BotOrder, BotOrders, RedeemHistorys} from './_models/order';
import {Store} from './_models/store';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class BusinessService {
  public isDisconnectMessage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public reconnectSocket: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public connectionStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public orderSet = new BehaviorSubject<BotOrder[]>([]);

  constructor(private http:HttpClient){}

  findBotOrders(storeId:number, filter = '', startDate=null, endDate=null,
    sortField='id', sortOrder='asc', pageNumber=0, pageSize=10, cashierId=''){

    let cashier_id = localStorage.getItem('posLoggedUser')
    let params = new HttpParams()
    .set('store_id', storeId.toString())
    .set('filter', filter)
    .set('sort_field', sortField)
    .set('sort_dir', sortOrder)
    .set('page_number', pageNumber.toString())
    .set('page_size', pageSize.toString())
    .set('closed_tab', "true")
    .set('cashier_id', cashier_id || '')

    if(startDate){
      params = params.set('start_date', startDate.toISOString().slice(0, 10))
    }

    if(endDate){
      params = params.set('end_date', endDate.toISOString().slice(0, 10))
    }

    return this.http.get<BotOrders>(environment.apiUrl + '/backend/business/', { params: params })
  }


  findOpenOrders(storeId:number,filter='', startDate=null, endDate=null,
    sortField='id', sortOrder='asc', pageNumber=0, pageSize=10, cashierId=''){
    let cashier_id = localStorage.getItem('posLoggedUser')
    let params = new HttpParams()
    .set('store_id', storeId.toString())
    .set('filter', filter)
    .set('sort_field', sortField)
    .set('sort_dir', sortOrder)
    .set('page_number', pageNumber.toString())
    .set('page_size', pageSize.toString())
    .set('open_tab', "true")
    .set('cashier_id', cashier_id || '')

    if(startDate){
      params = params.set('start_date', startDate.toISOString().slice(0, 10))
    }

    if(endDate){
      params = params.set('end_date', endDate.toISOString().slice(0, 10))
    }

    return this.http.get<BotOrders>(environment.apiUrl + '/backend/business/', { params: params })
  }



  getRedeemHistory(storeId:number, filter = '', startDate=null, endDate=null,
    sortField='id', sortOrder='asc', pageNumber=0, pageSize=10){
    let params = new HttpParams()
    .set('store_id', storeId.toString())
    .set('filter', filter)
    .set('sort_field', sortField)
    .set('sort_dir', sortOrder)
    .set('page_number', pageNumber.toString())
    .set('page_size', pageSize.toString())

    if(startDate){
      params = params.set('start_date', startDate.toISOString().slice(0, 10))
    }

    if(endDate){
      params = params.set('end_date', endDate.toISOString().slice(0, 10))
    }

    return this.http.get<RedeemHistorys>(environment.apiUrl + '/backend/business/offers/redeem_history/', { params: params })
  }
  getRedeemHistoyReferral(storeId: number, filter = '', startDate = null, endDate = null,
  sortField = 'id', sortOrder = 'asc', pageNumber = 0, pageSize = 10) {

  let params = new HttpParams()
    .set('store_id', storeId.toString())
    .set('filter', filter)
    .set('sort_field', sortField)
    .set('sort_dir', sortOrder)
    .set('page_number', pageNumber.toString())
    .set('page_size', pageSize.toString())

  if (startDate) {
    params = params.set('start_date', startDate.toISOString().slice(0, 10))
  }

  if (endDate) {
    params = params.set('end_date', endDate.toISOString().slice(0, 10))
  }

  return this.http.get<RedeemHistorys>(environment.apiUrl + '/backend/business/referral/redeem_history/', { params: params })
}



getReferralCsv(storeId: number, filter = '', startDate = null, endDate = null,
  sortField = 'id', sortOrder = 'asc', pageNumber = 0, pageSize = 10) {

  let params = new HttpParams()
    .set('store_id', storeId.toString())
    .set('filter', filter)
    .set('sort_field', sortField)
    .set('sort_dir', sortOrder)
    .set('page_number', pageNumber.toString())
    .set('page_size', pageSize.toString())

  if (startDate) {
    params = params.set('start_date', startDate.toISOString().slice(0, 10))
  }

  if (endDate) {
    params = params.set('end_date', endDate.toISOString().slice(0, 10))
  }

  return this.http.get(environment.apiUrl + '/backend/referral/export/redeem_user_history/', { params: params,responseType: "blob" })
}

  getStores(filter = ''){
    let params = new HttpParams().set('filter', filter)
    return this.http.get<Store[]>(environment.apiUrl + '/backend/business/stores/', {params: params})
  }

  undoRedemption(redemption){
    return this.http.post<RedeemHistorys>(environment.apiUrl + '/backend/business/offers/reverse/', JSON.stringify({
      "offer_item_id": redemption.offer_item.id,
      "redeem_history_id": redemption.id,
      "amount": redemption.redeemed_amount}))
  }

}
