import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BusinessService } from 'src/app/business.service';

@Component({
  selector: 'app-cashier-redemptions-dialog',
  templateUrl: './cashier-redemptions-dialog.component.html',
  styleUrls: ['./cashier-redemptions-dialog.component.scss']
})
export class CashierRedemptionsDialogComponent implements OnInit {
  otpValues: string[] = ['', '', '', '', '', ''];
  @ViewChild('input1') input1: ElementRef;
  @ViewChild('input2') input2: ElementRef;
  @ViewChild('input3') input3: ElementRef;
  @ViewChild('input4') input4: ElementRef;
  @ViewChild('input5') input5: ElementRef;
  @ViewChild('input6') input6: ElementRef;
  constructor(
    private businessService: BusinessService,
    private dialogRef: MatDialogRef<CashierRedemptionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }
  focusNext(index: number) {
    if (index < this.otpValues.length) {
      const nextInput = this[`input${index + 1}`] as ElementRef;
      nextInput.nativeElement.focus();
    }
  }

}
