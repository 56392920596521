<div *ngIf="isLoading; then processingBlock else resultBlock"></div>

<ng-template #processingBlock>
  <p class="cardPayment cardPayment-mobile xs:!text-2xl">
    Card Payment In Progress:
  </p>
  <mat-spinner class="spinner"></mat-spinner>
  <!-- <button mat-raised-button class="back" (click)="backClick()">
    Cancel
  </button> -->
</ng-template>

<ng-template #resultBlock>
  <div *ngIf="result.result_code === 'Approved'; then approvedBlock else notApprovedBlock"></div>
  <ng-template #approvedBlock>
    <span *ngIf="!store.feature_flag['manual_print_standard_checkout']">
      <div class="flex flex-col">
        <p class="cardPayment cardPayment-mobile aa">
          Card Payment:
        </p>
        <p class="approving mb-[125px] bb">Approved</p>
        <!-- <p class="codeStyle approved-message">{{result.message}}</p> -->
        <div class="flex justify-center px-11">
          <button mat-raised-button class="doneButton w-full !h-20 text-[27px]" color="primary" (click)="doneClick()">
            Ok
          </button>
        </div>
      </div>
    </span>
    <!-- WITH PRINT OPTIONS -->
    <div *ngIf="store.feature_flag['manual_print_standard_checkout']" class="flex flex-col gap-2 text-center mt-5">
      <p class="text-gray-600 text-3xl">Card Payment:</p>
      <p class="text-6xl text-green-600">Approved</p>
      <p class="text-gray-600 text-3xl">{{result.message}}</p>
      <app-manual-print-control [manualData]="manualData" (selectionEvent)="doneClick()"></app-manual-print-control>
    </div>

  </ng-template>

  <ng-template #notApprovedBlock>
    <p class="cardPayment-mobile colr mt-[78px] text-[33px] font-[Roboto] font-medium leading-4 tracking-[1.25px] text-center">
      Card Payment:
    </p>
    <p class="notApproving">Not Approved</p>
    <p class="codeStyle">{{result.message}}</p>

    <button  type="button" class="statusButton absolute left-[22px] bottom-11" (click)="checkStatus(terminalId, orderHash)">
      Status
    </button>

    <button class="doneButtons absolute right-[22px] bottom-11" color="primary-color" (click)="backClick()">
      Back
    </button>
  </ng-template>

</ng-template>

