import { Component, ElementRef, OnInit, ViewChild, } from '@angular/core';
import { DigitalDisplayService } from 'src/app/_services/digital-display.service';
import { environment } from 'src/environments/environment';
import { AddScreenDialogComponent } from '../add-screen-dialog/add-screen-dialog.component';
import { EditScreenDialogComponent } from '../edit-screen-dialog/edit-screen-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ScreenDeleteComponent } from '../screen-delete/screen-delete.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { DefaultScreenDialogComponent } from '../default-screen-dialog/default-screen-dialog.component';
import { ShowScreenService } from 'src/app/_services/show-screen.service';

export interface PeriodicElement {
  canvas: any;
  screen_id: any;
  content_type: any;
  screen_url: any;
}

@Component({
  selector: "app-d-d-screens",
  templateUrl: "./d-d-screens.component.html",
  styleUrls: ["./d-d-screens.component.scss"],
})
// @Pipe({ name: "safe" })
export class DDScreensComponent implements OnInit {
  displayedColumns: string[] = [
    "canvas",
    "screen_id",
    "content_type",
    "screen_url",
    "action",
  ];
  dataSource: MatTableDataSource<PeriodicElement>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  storeId: any;
  isLoading = false;
  storeArr: any;
  screenData: any;
  endpoint: any;
  leaflogixendpoint: any;
  showUrl: any = [];
  orderUrl;
  orderUrls;
  public elementSrc = [];
  ertertr: any;
  src;
  aaa: any = false;
  @ViewChild("iframe11", { static: false }) iframe11: ElementRef;
  abc: any;
  storeType: any;
  showScreen: any = '1';
  urlCreate: any;
  constructor(
    private digitalDisplayService: DigitalDisplayService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private showScreenService: ShowScreenService,
  ) {
  }
  ngOnInit() {
    this.endpoint = environment.apiUrl;
    this.leaflogixendpoint = environment.leaflogixApiEndpoint;
    this.storeId = localStorage.getItem("currentStore");
    this.storeType = localStorage.getItem("store_type");

    this.showScreenService.submit$.subscribe(submit => {
      if (submit) {
        this.showScreen = '1';
      }
    });

    this.getDisplayScreen();
    this.storeArr = environment.storeArr
  }
  getDisplayScreen() {
    this.isLoading = true;
    this.digitalDisplayService
      .getDisplayScreen(this.storeId)
      .subscribe((data) => {
        this.screenData = data.data;
        this.dataSource = new MatTableDataSource<PeriodicElement>(
          this.screenData
        );
        this.isLoading = false;
        var idInt = parseInt(this.storeId);
        this.dataSource.paginator = this.paginator;
        this.abc = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.leaflogixendpoint + `/pos-display/web/screens/?store_id=1&tv_id=3`
        );
        for (let i = 0; i < this.screenData.length; i++) {
          const element = this.screenData[i].tv_id;
          if (this.storeType == "Leaflogix") {
            this.orderUrl = this.leaflogixendpoint + "`/pos-display/web/screens/?store_id=" + this.storeId + "&tv_id=" + element;
          }
          else {
            this.orderUrl = this.endpoint + "/backend/v2/pos-display/web_screens/?store_id=" + this.storeId + "&tv_id=" + element;
          }
          const aaa = {
            url: this.sanitizer.bypassSecurityTrustResourceUrl(
              `${this.orderUrl}`
            ),
          };
          this.showUrl.push(aaa);
        }
      });
  }
  deleteDisplayScreen(id) {
    this.digitalDisplayService.deleteDisplayScreen(id).subscribe((data) => {
      this.getDisplayScreen();
    });
  }
  copyScreen(id) {
    this.isLoading = true;
    this.digitalDisplayService.copyDisplayScreen(this.storeId, id).subscribe((data) => {
      this.getDisplayScreen();
      this.isLoading = false;
    });
  }
  addScreen() {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(AddScreenDialogComponent, {
      width: isMobile ? '100vw' : '',
      height: isMobile ? '100vh' : '',
      maxWidth: isMobile ? '100vw' : '80vw',
      data: {},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.yesSubmit == "YesSubmit") {
        this.getDisplayScreen();
      }
    });
  }
  editScreen(element) {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(EditScreenDialogComponent, {
      width: isMobile ? '100vw' : '',
      height: isMobile ? '100vh' : '',
      maxWidth: isMobile ? '100vw' : '80vw',
      data: element,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.yesSubmit == "YesSubmit") {
        this.getDisplayScreen();
      }
    });
  }

  // previewScreen(element) {
  //   let urlCreate;

  //   if (this.storeType === "Leaflogix") {
  //     urlCreate = `${this.leaflogixendpoint}/pos-display/1/${this.storeId}/${element.tv_id}/`;
  //     // window.open(urlCreate, '_blank');

  //     this.router.navigate(['/home/digital-display-screen'], {
  //       state: { data: urlCreate }
  //     });
  //   } else {
  //     urlCreate = `${this.endpoint}/backend/v2/pos-display/screens/?store_id=${this.storeId}&tv_id=${element.tv_id}`;
  //     // window.open(urlCreate, '_blank');

  //     this.router.navigate(['/home/digital-display-screen'], {
  //       state: { data: urlCreate }
  //     });
  //   }
  // }


  previewScreen(element) {
    let urlCreate: string;
    let type: string;

    if (this.storeType === "Leaflogix") {
      urlCreate = `${this.leaflogixendpoint}/pos-display/1/${this.storeId}/${element.tv_id}/`;
      type = '1';
    } else {
      urlCreate = `${this.endpoint}/backend/v2/pos-display/screens/?store_id=${this.storeId}&tv_id=${element.tv_id}`;
      type = '2';
    }

    const routerUrl = `${window.location.origin}/#/digital-display-screen/${this.storeId}/${element.tv_id}/${type}`;
    window.open(routerUrl, '_blank');
  }

  deleteScreens(element) {
    let dialogRef = this.dialog.open(ScreenDeleteComponent, {
      data: {
        category: 3,
        id: element,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.yesSubmit == "YesSubmit") {
        this.getDisplayScreen();
      }
    });
  }
  defaultScreen() {
    const isMobile = window.innerWidth <= 599;
    let dialogRef = this.dialog.open(DefaultScreenDialogComponent, {
      data: {
        view: 'select'
      },
      width: isMobile ? '100vw' : '550px',
      height: isMobile ? '100vh' : '',
      maxWidth: isMobile ? '100vw' : '80vw',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.yesSubmit == "YesSubmit") {
        this.getDisplayScreen();
      }
    });
  }
  defaultScreenSet(id) {
    const isMobile = window.innerWidth <= 599;
    let dialogRef = this.dialog.open(DefaultScreenDialogComponent, {
      data: {
        id: id,
        view: 'set'
      },
      width: isMobile ? '100vw' : '550px',
      height: isMobile ? '100vh' : '',
      maxWidth: isMobile ? '100vw' : '80vw',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.yesSubmit == "YesSubmit") {
        this.getDisplayScreen();
      }
    });
  }
}
