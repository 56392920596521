import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DigitalDisplayService {


  endpoint: any;
  leaflogixendpoint: any;
  menuEndpoint: any;
  orderEndpoint: any;
  storeId: any;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  storeType: any;
  constructor(private http: HttpClient,) {
    this.storeId = localStorage.getItem('currentStore');
    this.storeType = localStorage.getItem('store_type');
    let idInt = parseInt(this.storeId);
    this.endpoint = environment.apiUrl;
    this.menuEndpoint = `${this.endpoint}/backend/`;
    this.leaflogixendpoint = `${environment.leaflogixApiEndpoint}/pos-display/`;

    if (this.storeType == 'Leaflogix') {
      this.orderEndpoint = this.leaflogixendpoint;
    }
    else {
      this.orderEndpoint = this.menuEndpoint;
    }

  }
  unreadMessages(storeId: any) {
    this.storeId = localStorage.getItem('currentStore');
    this.storeType = localStorage.getItem('store_type');
    let idInt = parseInt(this.storeId);
    this.endpoint = environment.apiUrl;
    this.menuEndpoint = `${this.endpoint}/backend/`;
    this.leaflogixendpoint = `${environment.leaflogixApiEndpoint}/pos-display/`;
    if (this.storeType == 'Leaflogix') {
      this.orderEndpoint = this.leaflogixendpoint;
    }
    else {
      this.orderEndpoint = this.menuEndpoint;
    }
  }

  getDisplayScreen(storeId: string) {
    return this.http.get<any>(
      `${this.orderEndpoint}screen/${storeId}/`
    );
  }


  deleteDisplayScreen(Id: any) {
    return this.http.delete<any>(
      `${this.orderEndpoint}screen/${Id}/`
    );
  }

  copyMenuCat(data) {
    return this.http.post<any>(
      `${this.orderEndpoint}mastercategories/copy/`, data,
    );
  }

  deleteScreen(Id: any) {
    return this.http.delete<any>(
      `${this.orderEndpoint}screen/${Id}/`
    );
  }

  editDisplayScreen(Id: any, data) {
    return this.http.put<any>(
      `${this.orderEndpoint}screen/${Id}/`, data,
    );
  }

  copyDisplayScreen(storeId: string, Id: any) {
    return this.http.post<any>(
      `${this.orderEndpoint}screen/copy/${storeId}/${Id}/`, '',
    );
  }

  addDisplayScreen(data) {
    return this.http.post<any>(
      `${this.orderEndpoint}add/screen/`, data,
    );
  }

  getMasterCategory(storeId: any) {
    return this.http.get<any>(
      `${this.orderEndpoint}mastercategories/${storeId}/`
    );
  }

  addMasterCategory(payload: any) {
    return this.http.post(`${this.orderEndpoint}add/mastercategory/`, payload, this.httpOptions);
  }

  editMasterCategory(Id: any, data) {
    return this.http.put<any>(
      `${this.orderEndpoint}mastercategories/${Id}/`, data,
    );
  }

  deleteMasterCategory(Id: any) {
    return this.http.delete<any>(
      `${this.orderEndpoint}mastercategories/${Id}/`
    );
  }

  subCategoryList(Id: any) {
    return this.http.get(`${this.orderEndpoint}menuitem/option/list/${Id}/`, this.httpOptions);
  }

  SubCategoriesOtionListMaster(storeId: any) {
    return this.http.get<any>(
      `${this.orderEndpoint}sub_cat/menu/option/list/${storeId}/`
    );
  }
  ModifierGroupOtionListMaster(storeId: any) {
    return this.http.get<any>(
      `${this.orderEndpoint}modifier/menu/group/option/list/${storeId}/`
    );
  }

  ImageMediaListGet(storeId: any) {
    return this.http.get<any>(`${this.orderEndpoint}screen/image/${storeId}/`);
  }
  addMedia(payload: any) {
    return this.http.post(`${this.orderEndpoint}screen/image/add/`, payload);
  }

  editMedia(payload: any, Id: any) {
    return this.http.put(`${this.orderEndpoint}screen/image/${Id}/update/`, payload);
  }

  deleteMedia(Id: any) {
    return this.http.delete(`${this.orderEndpoint}screen/image/${Id}/delete/`);
  }

  restaurantListGet(storeId: any) {
    return this.http.get<any>(`${this.orderEndpoint}cat/image/${storeId}/`);
  }
  addrestauran(payload: any) {
    return this.http.post(`${this.orderEndpoint}cat/image/add/`, payload);
  }
  editrestauran(payload: any, Id: any) {
    return this.http.put(`${this.orderEndpoint}cat/image/${Id}/`, payload);
  }
  deleterestauran(Id: any) {
    return this.http.delete(`${this.orderEndpoint}cat/image/${Id}/`);
  }

  videoListGet(storeId: any) {
    return this.http.get<any>(`${this.orderEndpoint}screen/video/${storeId}/`);
  }
  addVideo(payload: any) {
    return this.http.post(`${this.orderEndpoint}screen/video/add/`, payload);
  }

  editVideo(payload: any, Id: any) {
    return this.http.put(`${this.orderEndpoint}screen/video/${Id}/`, payload);
  }

  deleteVideo(Id: any) {
    return this.http.delete(`${this.orderEndpoint}screen/video/${Id}/`);
  }

  getEmojiList() {
    return this.http.get(`${this.orderEndpoint}list/emoji/`);
  }



  editCategory(Id: any, data) {
    return this.http.put<any>(
      `${this.orderEndpoint}update/category/${Id}/`, data,
    );
  }
  deleteCategory(Id: any) {
    return this.http.delete<any>(
      `${this.orderEndpoint}delete/category/${Id}/`
    );
  }


  editSubCategory(Id: any, data) {
    return this.http.put<any>(
      `${this.orderEndpoint}update/subcategory/${Id}/`, data,
    );
  }

  CategoriesOtionList(storeId: any) {
    return this.http.get<any>(
      `${this.orderEndpoint}categories/option/list/${storeId}/`
    );
  }

  SubCategoriesOtionList(storeId: any) {
    return this.http.get<any>(
      `${this.orderEndpoint}subcategories/option/list/${storeId}/`
    );
  }

  getCheckCategories(storeId: any) {
    return this.http.get<any>(`${this.orderEndpoint}check_categories/${storeId}/`);
  }

  subCategoriesAdd(storeId: any) {
    return this.http.get<any>(`${this.orderEndpoint}save/category/${storeId}/`);
  }

  addCategory(payload: any) {
    return this.http.post(`${this.orderEndpoint}add/category/`, payload, this.httpOptions);
  }
  addSubCategory(payload: any) {
    return this.http.post(`${this.orderEndpoint}add/subcategory/`, payload, this.httpOptions);
  }
  defaultScreenList() {
    return this.http.get<any>(`${this.orderEndpoint}default/screens/`);
  }
  defaultScreenSet(data) {
    return this.http.post<any>(`${this.orderEndpoint}default/screens/`, data);
  }
  newScreenSet(data) {
    return this.http.post<any>(`${this.orderEndpoint}default/to/new/screens/`, data);
  }
  addTVScreen(data) {
    return this.http.post<any>(`${this.orderEndpoint}tv/screens/`, data);
  }
  updateTVScreen(Id, data) {
    return this.http.put<any>(`${this.orderEndpoint}tv/screens/${Id}/`, data);
  }

  getTVScreen(storeId) {
    return this.http.get<any>(`${this.orderEndpoint}tv/screens/${storeId}/`);
  }

  deleteTVScreen(Id: any) {
    return this.http.delete<any>(
      `${this.orderEndpoint}tv/screens/${Id}/`
    );
  }

  getdefaultMenuCategorie(storeId) {
    return this.http.get<any>(`${this.orderEndpoint}default/mastercategories/${storeId}/`);
  }
  updatedefaultMenuCategorie(Id, data) {
    return this.http.put<any>(`${this.orderEndpoint}default/mastercategories/${Id}`, data);
  }
  deletedefaultMenuCategorie(Id) {
    return this.http.delete<any>(`${this.orderEndpoint}default/mastercategories/${Id}/`);
  }
  defaultToNewMenuCategory(data: any) {
    return this.http.post<any>(`${this.orderEndpoint}default/mastercategories/to/mastercategories/`, data);
  }

  dragdropMasterCategory(payload: any) {
    return this.http.post(`${this.orderEndpoint}drag_and_drop/mastercategories/`, payload, this.httpOptions);
  }
}
