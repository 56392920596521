import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import {User} from '../_models/user';
import {TimeClock} from '../_models/time-clock';
import {environment} from 'src/environments/environment';
import Utils from 'src/app/utils';
import {BehaviorSubject} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService {

  public isLoaderOff: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public getUserList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient) { }

  getAll(store, username) {
    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0'
    });
    return this.http.post<User[]>(`${environment.apiUrl}/backend/v2/user/${store}/all_users/`, {username}, {headers});
  }

  getRoles(store, username) {
    return this.http.post<any[]>(`${environment.apiUrl}/backend/v2/user/${store}/all_roles/`, {username});
  }

  getAllUser(store, username){
    return this.http.post<User[]>(`${environment.apiUrl}/backend/v2/user/${store}/all_users_least_detail/`, {username});
  }

  getPOSAllUser(store){
    return this.http.get<User[]>(`${environment.apiUrl}/backend/v2/user/${store}/pos_users_list/`);
  }

  editRole(role, store) {
    return this.http.post<any[]>(`${environment.apiUrl}/backend/v2/user/${store}/edit_role/`, {role});
  }

  updateUser(user) {
    return this.http.put<User>(`${environment.apiUrl}/backend/v2/user/${user.id}/`, user);
  }

  updateUserTimeClock(data) {
    return this.http.put<any>(`${environment.apiUrl}/backend/time-clock/update/${data.pk}/`, data);
  }

  deleteTimeClockSession(data) {
    return this.http.delete<any>(`${environment.apiUrl}/backend/time-clock/${data.pk}/`, data);
  }

  getCurrentRecord(data) {
    let params = new HttpParams().set('user', data['user']).set('store', data['store']);
    return this.http.get<TimeClock>(`${environment.apiUrl}/backend/v2/time-clock/current_time_record/`, {params: params});
  }

  checkTimeClockStatus(storeId, userId){
    let params = new HttpParams()
    .set('store_id', storeId.toString())
    .set('user_id', userId.toString())
    return this.http.get<any>(`${environment.apiUrl}/backend/v2/time-clock/time_clock_status/`, {params: params});
  }

  getAllTimeRecords(storeId, filter, sortField, sortOrder, pageNumber, pageSize, start_date, end_date){
    let params = new HttpParams()
    .set('store_id', storeId.toString())
    .set('page_number', pageNumber.toString())
    .set('page_size', pageSize.toString())
    .set('start_date', start_date.toString())
    .set('end_date', end_date.toString())
    return this.http.get<any>(`${environment.apiUrl}/backend/v2/time-clock/all_today_time_clocks/`, {params: params});
  }

  getAllUsersTimeRecords(storeId, filter, startDate, endDate, sortField, sortOrder, pageNumber, pageSize){
    let params = new HttpParams()
    .set('store_id', storeId.toString())
    .set('filter', filter)
    .set('sort_field', sortField)
    .set('sort_dir', sortOrder)
    .set('page_number', pageNumber.toString())
    .set('page_size', pageSize.toString())
    if(startDate){
      // params = params.set('start_date', startDate.toISOString().slice(0, 10))
      params = params.set('start_date', Utils.toISOLocal(startDate).slice(0, 10))
    }

    if(endDate){
      // params = params.set('end_date', endDate.toISOString().slice(0, 10))
      params = params.set('end_date', Utils.toISOLocal(endDate).slice(0, 10))
    }
    return this.http.get<any>(`${environment.apiUrl}/backend/time-clock/${storeId}/all_users_time_clocks/`, {params: params});
  }

  getFilteredTimeRecords(storeId, filter, startDate, endDate, sortField, sortOrder, pageNumber, pageSize){
    let params = new HttpParams()
    .set('store_id', storeId.toString())
    .set('filter', filter)
    .set('sort_field', sortField)
    .set('sort_dir', sortOrder)
    .set('page_number', pageNumber.toString())
    .set('page_size', pageSize.toString())
    if(startDate){
      // params = params.set('start_date', startDate.toISOString().slice(0, 10))
      params = params.set('start_date', Utils.toISOLocal(startDate).slice(0, 10))
    }

    if(endDate){
      // params = params.set('end_date', endDate.toISOString().slice(0, 10))
      params = params.set('end_date', Utils.toISOLocal(endDate).slice(0, 10))
    }
    return this.http.get<any>(`${environment.apiUrl}/backend/v2/time-clock/all_time_clocks/`, {params: params});
  }

  addUser(user) {
    return this.http.post<User>(`${environment.apiUrl}/backend/v2/user/`, user);
  }

  deleteUser(user) {
    return this.http.delete<User>(`${environment.apiUrl}/backend/v2/user/${user.id}`);
  }

  updateUserBreakTimeClock(data, store_id) {
    return this.http.put<any>(`${environment.apiUrl}/backend/time-clock/${data.pk}/${store_id}/break_time_update/`, data);
  }

  addUserTimeClock(store_id, payload) {
    return this.http.post(`${environment.apiUrl}/backend/${store_id}/add_timeclock/`, payload);
  }
}
