import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DigitalDisplayService } from 'src/app/_services/digital-display.service';

@Component({
  selector: 'app-screen-delete',
  templateUrl: './screen-delete.component.html',
  styleUrls: ['./screen-delete.component.scss']
})
export class ScreenDeleteComponent implements OnInit {
  getID: any;
  deleteID: any;
  isLoading = false;
  storeId: string;

  constructor(
    private digitalDisplayService: DigitalDisplayService,
    public dialogRef: MatDialogRef<ScreenDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log('id get 111111111111', data);

  }

  ngOnInit() {
    this.storeId = localStorage.getItem("currentStore");
    this.getID = this.data.category;
    this.deleteID = this.data.id;
  }
  onNoClick() {
    this.dialogRef.close();
  }

  categoryDelete() {
    this.isLoading = true;
    if (this.getID == 1) {
      this.digitalDisplayService.deleteCategory(this.deleteID).subscribe(data => {
        const resData = { yesSubmit: "YesSubmit" };
        this.isLoading = false;

        this.dialogRef.close(resData);
      })
    }
    else if (this.getID == 2) {
      this.digitalDisplayService.deleteMasterCategory(this.deleteID).subscribe(data => {
        const resData = { yesSubmit: "YesSubmit" };
        this.isLoading = false;
        this.dialogRef.close(resData);
      })
    } else if (this.getID == 3) {
      this.digitalDisplayService.deleteScreen(this.deleteID).subscribe((data: any) => {
        const resData = { yesSubmit: "YesSubmit" };
        this.isLoading = false;
        this.dialogRef.close(resData);
      })
    }
    else if (this.getID == 4) {
      let keySend = {
        "menu_cat_id": this.deleteID,
        "store_id": this.storeId
      }
      this.digitalDisplayService.copyMenuCat(keySend).subscribe((data: any) => {
        const resData = { yesSubmit: "YesSubmit" };
        this.isLoading = false;
        this.dialogRef.close(resData);
      })
    }
  }

}
