import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MenuServiceV2} from 'src/app/_services/menu-v2.service';
import {MenuCategoryImageComponent} from '../menu-category-image/menu-category-image.component';
import {AlertsMessagesComponent} from 'src/app/alerts-messages/alerts-messages.component';
import { MenuResetOptions, Store } from 'src/app/_models/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

interface Option {
  id: number;
}
@Component({
  selector: 'app-menu-item-search-result',
  templateUrl: './menu-item-search-result.component.html',
  styleUrls: ['./menu-item-search-result.component.scss']
})
export class MenuItemSearchResultComponent implements OnInit {

  selectedMenuItemGroupForm;
  selectedMenuItemGroup;
  portions;
  expandedElement;
  menuItemOrder;

  @Input() menuItemGroup;
  @Input() store: Store;

  resetOptions = MenuResetOptions;
  private destroySubject: Subject<void> = new Subject();
  menuItemIdList: Option[] = [];
  filteredmenuItemId: Option[] = [];
  selectedJoinedWith: number | null = null;

  constructor(private dialog: MatDialog, private menuService:MenuServiceV2, private snackBar: MatSnackBar) { }

  ngOnInit() {
    console.log(this.menuItemGroup);
    this.portions = [];
    if(this.store && this.menuItemGroup){
      this.loadStoreMenuItemGroup(this.store.id, this.menuItemGroup.id);
    }

    this.menuService.getMenuItemIds(this.store.id).pipe(
      takeUntil(this.destroySubject)
    ).subscribe((data: Option[]) => {
      this.menuItemIdList = data;
      this.filteredmenuItemId = [...this.menuItemIdList];
    });
  }

  updateMenuItemGroup(values){
    console.log("update", values);
    this.menuService.updateStoreMenuItemGroup(this.store.id, this.selectedMenuItemGroup.id, JSON.stringify(values)).subscribe(data => {
      this.snackBar.open('Item has been updated', 'OK', { duration: 2000 });
      this.ngOnInit()
    })
  }

  addPortionExMenuItemGroup(values){
    console.log("add", values);
    this.selectedMenuItemGroupForm.get('menu_items').push(new UntypedFormGroup({
      portion: new UntypedFormControl('', Validators.required),
      base_price: new UntypedFormControl('', Validators.required),
      reset: new UntypedFormControl(''),
      barcode: new UntypedFormControl(''),
      food_cost: new UntypedFormControl(''),
      is_available: new UntypedFormControl(true, Validators.required),
      is_taxable: new UntypedFormControl(true, Validators.required),
      is_pos_only: new UntypedFormControl(false, Validators.required),
      available_qty: new UntypedFormControl(null)
    }))
  }

  loadStoreMenuItemGroup(storeId, menuItemId){
    console.log();

    this.menuItemOrder = []
    this.menuService.getStoreMenuItemGroup(storeId, menuItemId).subscribe(data => {
      this.selectedMenuItemGroup = data
      this.selectedMenuItemGroupForm  = new UntypedFormGroup({
        id: new UntypedFormControl(data.id, Validators.required),
        name: new UntypedFormControl(data.name, Validators.required),
        starting_price: new UntypedFormControl(data.starting_price, Validators.required),
        description: new UntypedFormControl(data.description),

        menu_items:new UntypedFormArray(data.menu_items.map(mi => {
          this.portions.push(mi.portion);
          this.menuItemOrder.push(mi.id);
          var formattedDateTime = null
          if (mi.reset_time) {
            var resetTimeDate = new Date(mi.reset_time);
            formattedDateTime = resetTimeDate.toLocaleString("en-US", {
              timeZone: localStorage.getItem('storeTimezone'),
              hour12: false,
            }).replace(',', '');
          }
          return new UntypedFormGroup({
            menu_group_id: new UntypedFormControl(data.id),
            id: new UntypedFormControl(mi.id, Validators.required),
            portion: new UntypedFormControl(mi.portion, Validators.required),
            description: new UntypedFormControl(mi.description),
            base_price: new UntypedFormControl(mi.base_price, Validators.required),
            barcode: new UntypedFormControl(mi.barcode),
            food_cost: new UntypedFormControl(mi.food_cost),
            is_available: new UntypedFormControl(mi.is_available, Validators.required),
            is_taxable: new UntypedFormControl(mi.is_taxable, Validators.required),
            is_pos_only: new UntypedFormControl(mi.is_pos_only, Validators.required),
            available_qty: new UntypedFormControl(mi.available_qty),
            reset: new UntypedFormControl(mi.reset),
            name: new UntypedFormControl(mi.name),
            menu_item_group_name: new UntypedFormControl(mi.menu_item_group_name),
            display_name: new UntypedFormControl(mi.display_name),
            joined_with: new UntypedFormControl(mi.joined_with || null),
            reset_time: new UntypedFormControl(formattedDateTime || null)
          })
        }))
      })
    })
  }

  openImageDialog(){
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(MenuCategoryImageComponent,{
      data:{
        menuItemGroup: this.selectedMenuItemGroup
      },
      width: isMobile ?'100vw' : '800px',
      height: isMobile ? '100vh' : '',
      maxWidth: isMobile ? '100vw' : '80vw',
    })
    dialogRef.afterClosed().subscribe(result => {
      this.loadStoreMenuItemGroup(this.store.id, this.selectedMenuItemGroup.id);
    })
  }

  delete(){
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: "Are you sure you want to delete?",
        for_which: 'confirm'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.menuService.deleteImage(this.store.id, this.selectedMenuItemGroup.id).subscribe(data => {
          this.loadStoreMenuItemGroup(this.store.id, this.selectedMenuItemGroup.id);
        })
      }
    });
  }

  deleteMenuItem(value){
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: "Are you sure to delete "+value.portion+"?",
        for_which: 'confirm'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.menuService.deleteMenuItem(this.store.id, JSON.stringify(value)).subscribe(response => {
          this.loadStoreMenuItemGroup(this.store.id, value.menu_group_id)
        })
      }
    });
  }

  getSelectedOptionLabel(joinedWithValue: any): string {
    const selectedOption = this.menuItemIdList.find(option => option.id === joinedWithValue);
    return selectedOption ? selectedOption.id.toString() : 'Assign ID';
  }

  filterOptions(value: string) {
    const filterValue = value.toLowerCase();
    this.filteredmenuItemId = this.menuItemIdList.filter(option => 
      option.id.toString().includes(filterValue)
    );
  }

  onOptionSelected(event: any): void {
    this.selectedJoinedWith = event.value;
  }

  generateBarcode(menuId: string, portion: string, displayName: string, type: boolean) {
    this.menuService.getGenerateBarcode(this.store.id, menuId).pipe(takeUntil(this.destroySubject)).subscribe({
      next: response => {
        const imageUrl = URL.createObjectURL(response);
        if (type) {
          // Download barcode
          const url = window.URL.createObjectURL(response);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${this.stringToSlug(portion)}-${this.stringToSlug(displayName)}.png`;
          a.click();
          window.URL.revokeObjectURL(url);
          this.snackBar.open('Barcode Downloaded', 'OK', { duration: 2000 });
        } else {
          // Print barcode
          const printWindow = window.open('', '_blank', 'width=600,height=400');
if (printWindow) {
  printWindow.document.write(`
    <html>
      <head>
        <style>
          @page {
            margin: 0; /* Remove default margins for the printed page */
          }
          body {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            margin: 0;
            padding: 0;
          }
          img {
            width: 2.5in; /* Recommended width for UPC-A barcode */
            height: auto; /* Maintain aspect ratio */
            margin: 0.25in; /* Margin to ensure quiet zone around barcodes */
          }
        </style>
      </head>
      <body>
        <img src="${imageUrl}" alt="UPC-A Barcode" />
        <script>
          window.onload = function() {
            window.print();
            window.onafterprint = function() {
              window.close();
            };
          };
        </script>
      </body>
    </html>
  `);
  printWindow.document.close();
} else {
  this.snackBar.open('Please allow pop-ups for this website to print the barcode.', 'OK', { duration: 10000 });
}
        }
      },
      error: (err: HttpErrorResponse) => {
        this.snackBar.open(`Error: Invalid Barcode`, 'OK', { duration: 3000 });
      }
    });
  }

  stringToSlug(text: string): string {
    return text
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');
  }


  
}
