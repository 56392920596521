<div *ngIf="pointsList.length == 0">
  <p>
    Referee gets X dollar, Referrer gets X dollar:
  </p>
  <button mat-stroked-button class="single-button appear-button" (click)="noReferralPromotion()">Add Promotion</button>
</div>

<div style="padding: 20px 0px 0px 0px;">
  <div>

    <div>Verification PIN</div>

    <form [formGroup]="myForm" (ngSubmit)="updatePin()" novalidate>
      <div>
        <mat-form-field appearance="outline" style="margin-top: 15px;width:25%;">

          <input type="number" name="mob" matInput [(ngModel)]="showPin" name="mob" formControlName="mob">
          <mat-hint *ngIf="m.mob.invalid" style="color: red;">
            <mat-hint *ngIf="m.mob.errors?.required" style="color: red;">Please enter pin</mat-hint>
            <mat-hint *ngIf="m.mob.errors?.pattern" style="color: red;">PIN number must be exactly six digits</mat-hint>
          </mat-hint>
        </mat-form-field>
        <button mat-stroked-button class="top-save-button" style="width: 80px;" [disabled]="!myForm.valid"
          type="submit">Save</button>

        <button mat-stroked-button class="button-addtiers" (click)="addReferralPromotionRow()">Add Promotion</button>

      </div>
    </form>


  </div>
  <div>

    <div>Custom Default Message</div>

    <mat-form-field appearance="outline" class="" style="margin-top: 15px;width:25%;">

      <textarea matInput [(ngModel)]="custom_default_message"></textarea>
    </mat-form-field>
    <button mat-stroked-button class="top-save-button" style="width: 80px;" (click)="updateCustom()">Save</button>
  </div>
</div>





<div fxLayout="column" class="content-seting" *ngIf="pointsList.length != 0">





  <div>
    <p>
      Referee gets X dollar, Referrer gets X dollar:
    </p>
  </div>

  <div fxLayout="row" class="gap-top" fxFlex.gt-md="100" *ngFor="let point of pointsList">
    <div fxLayout="row" fxFlex.gt-md="100" style="width: 100%;">

      <mat-form-field appearance="outline" class="points-field" style="width:33%;">
        <input type="text" matInput value="" placeholder="Campaign Name" id="campaignName" name="campaignName" #campaignName matInput value="{{point.campaign_name}}">
      </mat-form-field>


      <div class="text-set ref-set-width" style="text-align: left; margin-left: 14px;">Referee gets dollar</div>

      <mat-form-field appearance="outline" class="points-field" style="width: 260px;">
        <input type="number" id="username" name="username" #username matInput value="{{point.referee_gets_dollar}}"
          oninput="validity.valid||(value='')">
      </mat-form-field>
      <div class="text-set ref-set-width" style="text-align: left; margin-left: 14px;">Referrer gets dollar</div>

      <mat-form-field appearance="outline" class="points-field" style="width: 260px;">

        <input type="number" min="0" oninput="validity.valid||(value='')" id="username2" name="username2" #username2
          matInput value="{{point.referrer_gets_dollar}}">
      </mat-form-field>
      <button mat-stroked-button class="button-save"
        (click)="updatePromotionValue(username.value, username2.value, point, campaignName.value)">Save</button>
      <button mat-stroked-button class="button-delet" (click)="deleteReferralPromotionRow(point)">Delete</button>
      <div>
        <mat-slide-toggle [(ngModel)]="point.is_enabled" (change)="disabledReferralPromotion(point)"
          style="position: relative;top: 16px;">
          <span *ngIf="point.is_enabled == true"> Enabled</span>
          <span *ngIf="point.is_enabled == false ">Disable </span>
        </mat-slide-toggle>

      </div>&nbsp;&nbsp;

      <div class="set-refe text-set ref-set-width" style="cursor: pointer;">
        <div>
          <span class="material-symbols-outlined" style="color: #3399ff" (click)="copyData(point)">
            link
          </span>
        </div>&nbsp;&nbsp;
        <div (click)="copyData(point)">
          <a>Referral Link</a>
        </div>
      </div>
    </div>
  </div>
</div>